<template>
    <div>
        <div class="mt-4 mb-8 flex place-content-center place-items-center" v-if="overLimitActive">
            <div class="mr-4 w-12 h-12 flex justify-center items-center">
                <feather-icon class="text-danger" icon="AlertTriangleIcon" svgClasses="h-12 w-12" />
            </div>
            <span class="text-xl font-semibold" v-if="overLimitActive">整篩活動報名人數已達上限，是否仍要繼續報名？</span>
        </div>
        <div class="flex justify-end items-center space-x-2">
            <vs-button class="rounded whitespace-no-wrap" type="flat" @click="close">關閉</vs-button>
            <vs-button class="rounded whitespace-no-wrap" @click="join">仍要報名</vs-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        overLimitActive: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    methods: {
        join() {
            this.$bus.$emit('overLimitAccept')
        },
        close() {
            this.$bus.$emit('overLimitCancel')
        },
    },
}
</script>
