import { render, staticRenderFns } from "./index.vue?vue&type=template&id=39f36d90"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=39f36d90&prod&lang=css"
import style1 from "./index.vue?vue&type=style&index=1&id=39f36d90&prod&lang=scss"
import style2 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports