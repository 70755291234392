<template>
    <div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <div class="vx-card sm:p-4 p-2">
                    <!-- 資料載入中 -->
                    <h5 v-if="loadingFlag" class="my-2 text-center">{{ $t('loading') }}</h5>
                    <!-- 資料 -->
                    <vs-tabs class="tabs-shadow-none" v-if="!loadingFlag && tabs">
                        <div v-for="(items, index) in tabs">
                            <vs-tab :label="geti18n(items[1].i18n)" :key="index" v-if="items[1].show == true" @click="tag1(index)">
                                <div class="py-1 divide-y" v-for="(item, i) in lifeHealthData[items[0]]">
                                    <HealthDataItem :item="item">
                                        <template slot="button">
                                            <vs-button icon="show_chart" :disabled="item.value == ''" @click="viewCurChart(item)"></vs-button>
                                            <vs-button icon="add" @click="addPersonalSign(item.type)" v-if="items[1].plus" :disabled="item.type == 'hr_detect'"></vs-button>
                                        </template>
                                    </HealthDataItem>
                                </div>
                            </vs-tab>
                        </div>
                    </vs-tabs>
                    <!-- 圖表 視窗 -->
                    <el-dialog :title="chartTitle" :visible.sync="popupActivChart" width="75%" append-to-body>
                        <vs-checkbox v-if="series[0].name == 'hr_detect'" v-model="hr_detect_abnormal_check" style="float: right; padding-bottom: 1rem; padding-right: 1rem" dark>只顯示異常</vs-checkbox>
                        <vs-row v-if="series[0].name != 'hr_detect'">
                            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                <div class="w-full" style="padding-top: 1em">
                                    <apexchart type="area" height="400vh" ref="lineChart" :options="chartOptions" :series="series"></apexchart>
                                </div>
                            </vs-col>
                            <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                                <VuePerfectScrollbar class="w-full" style="height: 400px; text-align: center; padding-top: 1em" :settings="settings">
                                    <div class="flex whitespace-no-wrap">
                                        <div class="mb-2 w-24">
                                            <span>數值</span>
                                        </div>
                                        <div class="mb-2 w-48">
                                            <span>量測時間</span>
                                        </div>
                                    </div>
                                    <div class="flex whitespace-no-wrap" v-for="(item, index) in tableData" :key="index">
                                        <div class="w-24 truncate" :class="item.color == true ? 'text-danger' : ''" style="font-size: 1rem">
                                            <span>{{ item.value }}</span>
                                        </div>
                                        <div class="w-48 truncate">
                                            <span>{{ item.time }}</span>
                                        </div>
                                    </div>
                                </VuePerfectScrollbar>
                            </vs-col>
                        </vs-row>
                        <vs-row v-else>
                            <vs-card v-for="(item, index) in tableData" :key="index">
                                <div style="padding-bottom: 0.5rem; font-size: 1rem">{{ item.measuretime }}</div>
                                <div class="healthdata_list_card_item">
                                    <span>{{ $t('emr.healthdata.afib') }}</span>
                                    <span :class="item.mtype_70_color ? 'red' : ''">{{ item.mtype_70 }}</span>
                                </div>
                                <div class="healthdata_list_card_item">
                                    <span>{{ $t('emr.healthdata.arr') }}</span>
                                    <span :class="item.mtype_71_color ? 'red' : ''">{{ item.mtype_71 }}</span>
                                </div>
                                <div class="healthdata_list_card_item">
                                    <span>{{ $t('emr.healthdata.pc') }}</span>
                                    <span :class="item.mtype_72_color ? 'red' : ''">{{ item.mtype_72 }}</span>
                                </div>
                                <div class="healthdata_list_card_item">
                                    <span>{{ $t('emr.healthdata.ihb') }}</span>
                                    <span :class="item.mtype_73_color ? 'red' : ''">{{ item.mtype_73 }}</span>
                                </div>
                            </vs-card>
                            <vs-card v-if="Object.keys(tableData).length == 0" style="display: flex; justify-content: center; align-items: center; height: 100%">
                                <span style="font-size: 1rem; text-align: center">無資料</span>
                            </vs-card>
                        </vs-row>
                    </el-dialog>
                </div>
            </vs-col>
        </vs-row>
        <!-- 新增數據 視窗 -->
        <vs-popup classContent="popup-example" :title="$t('AddData')" :active.sync="popupAddSignDig">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <vs-input class="inputx" placeholder="" v-model="signValue" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <vs-button @click="updateSign" color="primary" type="filled">{{ $t('AddBtn') }}</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { updateHealthdataFromEmr } from '@/api/user'
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import HealthDataItem from '@/components/Emr/HealthData/HealthDataItem.vue'
export default {
    components: {
        apexchart: VueApexCharts,
        VuePerfectScrollbar,
        HealthDataItem,
    },
    props: {
        userPid: {
            type: String,
            required: true,
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        partnerid() {
            return this.$store.getters.partner
        },
        tabs() {
            // 第一層tabs
            let data = JSON.parse(localStorage.getItem('his_config')).emr.life.contents
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            console.log(ArrayObj)
            return ArrayObj
        },
        lang() {
            return this.$i18n.locale
        },
    },
    data() {
        return {
            loadingFlag: true,
            selectType: '',
            signValue: 0,
            popupAddSignDig: false,
            popupActivChart: false,
            series: [
                {
                    name: this.$t('Height'),
                    data: [94, 90, 91, 135, 133, 95],
                },
            ],
            chartOptions: {
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth',
                },
                // colors: this.themeColors,
                yaxis: {
                    forceNiceScale: true,
                },
                xaxis: {
                    type: 'datetime',
                    categories: ['2020-01-06', '2020-03-26', '2020-06-29', '2020-08-04', '2020-08-06', '2020-11-27'],
                },
                tooltip: {
                    x: {
                        format: 'yyyy-MM-dd',
                    },
                },
                // 每個點的設定
                // markers: {
                //   size: 2, // 設置點的大小
                //   // strokeColors: '#36bf36', // 設置點的邊框顏色，此例中設置為白色
                //   strokeWidth: 0.5, // 設置點的邊框寬度
                //   fillOpacity: 1, // 設置點的填充透明度為 0，即空心點
                //   hover: {
                //     size: 2 // 設置當滑鼠懸停在點上時的大小
                //   }
                // },
                // line 線
                stroke: {
                    show: true,
                    colors: ['#003f8e'],
                    width: 1,
                },
                fill: {
                    // colors: ['#003f8e'], // 這將設定線條下方區域的顏色
                    type: 'gradient', // 這將設定填充類型，可以是 'solid'、'gradient' 等
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100],
                        colorStops: [
                            {
                                offset: 0,
                                color: '#003f8e', // 开始颜色 (藍色)
                                opacity: 1,
                            },
                            {
                                offset: 100,
                                color: '#889db8', // 结束颜色 (灰色)
                                opacity: 1,
                            },
                        ],
                    },
                },
            },
            chartTitle: '',
            tableData: [],
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            pid: 0,
            lifeHealthData: {},
            hr_detect_abnormal_check: false,
            item_select: [],
        }
    },
    watch: {
        popupActivChart(v) {
            if (v == false) {
                this.series[0].data = []
                this.chartOptions = {
                    xaxis: {
                        categories: ['2020-01-06', '2020-03-26', '2020-06-29', '2020-08-04', '2020-08-06', '2020-11-27'],
                    },
                }
            }
        },
        hr_detect_abnormal_check(index) {
            console.log(index)
            this.viewCurChart(this.item_select)
        },
    },
    created() {
        this.pid = this.$route.params.pid
        console.log('pid', this.pid)
        this.getPersonSign()
    },
    async mounted() {
        // 取生命與健康數值
        console.log('mounted.tabs', this.tabs)
    },
    methods: {
        // 新增數據 修改寫health data
        // saveSign(){
        //   var _self = this
        //   if(_self.signValue==0){
        //     _self.AddFailedNotify()
        //     return false
        //   }
        //   saveSignByType({ spid: _self.userPid, type: _self.selectType, value: _self.signValue }).then((res) => {
        //     if(res.data.status=='OK'){
        //       _self.addSuccessNotify()
        //       var array = _.cloneDeep(_self.lifeHealthData)
        //       array.forEach(function(v){
        //         if(_.find(v, ['type', _self.selectType])){
        //           _.set(_.find(v, ['type', _self.selectType]), 'value', _self.signValue)
        //           _.set(_.find(v, ['type', _self.selectType]), 'time', _self.getTime())
        //         }
        //       })
        //       _self.lifeHealthData = array
        //     }else{
        //       _self.AddFailedNotify()
        //     }
        //   }).then(() => {
        //     _self.selectType = ""
        //     _self.signValue = 0
        //     _self.popupAddSignDig=false
        //   })
        // },
        // updateHealthdataFromEmr 新增數據 修改寫health data
        updateSign() {
            var _self = this
            if (_self.signValue == 0) {
                _self.AddFailedNotify()
                return false
            }
            updateHealthdataFromEmr({ spid: _self.userPid, type: _self.selectType, value: _self.signValue, partner_id: _self.partnerid })
                .then((res) => {
                    if (res.data.status == 'OK') {
                        _self.addSuccessNotify()
                        var array = _.cloneDeep(_self.lifeHealthData)
                        array.forEach(function (v) {
                            if (_.find(v, ['type', _self.selectType])) {
                                _.set(_.find(v, ['type', _self.selectType]), 'value', _self.signValue)
                                _.set(_.find(v, ['type', _self.selectType]), 'time', _self.getTime())
                            }
                        })
                        _self.lifeHealthData = array
                    } else {
                        _self.AddFailedNotify()
                    }
                })
                .then(() => {
                    _self.selectType = ''
                    _self.signValue = 0
                    _self.popupAddSignDig = false
                })
        },
        // 資料新增成功提醒
        addSuccessNotify() {
            this.$vs.notify({
                color: 'grey',
                // title: this.$t('AddSuccess'),
                text: this.$t('AddSuccess'),
                position: 'top-center',
            })
        },
        // 資料新增失敗提醒
        AddFailedNotify() {
            this.$vs.notify({
                color: 'danger',
                title: this.$t('AddFailed'),
                text: this.$t('EnterCorrectValue'),
                position: 'top-center',
            })
        },
        // 開啟 新增數據 視窗
        addPersonalSign(type) {
            this.selectType = type
            this.popupAddSignDig = true
        },
        // 開啟 圖表 視窗
        viewCurChart(item) {
            console.log('item', item)
            this.chartTitle = this.lang == 'en' ? item.name : item.cn
            this.series[0].name = item.type
            this.item_select = item
            this.updateCurChart()
        },
        // 產生圖表
        updateCurChart() {
            var _self = this
            var data = null
            var temp_s_time = ''
            var item = _self.item_select
            _self.$vs.loading()
            if (item.model == 'sign') {
                // 心率 或 焦慮
                if (item.type == 'pulse_rate' || item.type == 'fatigue') {
                    temp_s_time = this.$moment(new Date(), 'YYYY-MM-DD').add(-6, 'month').format('YYYY-MM-DD')
                } else {
                    temp_s_time = this.$moment(new Date(), 'YYYY-MM-DD').add(-1, 'y').format('YYYY-MM-DD')
                }
                data = {
                    spid: _self.userPid,
                    type: item.type,
                    s_time: temp_s_time,
                    e_time: this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    abnormal: _self.hr_detect_abnormal_check,
                }
                axios
                    .post('/api/personalsignlist', data)
                    .then((res) => {
                        if (res.data.status == 'OK') {
                            var rsdata = res.data.list
                            _self.tableData = rsdata
                            var newData = rsdata.map((item) => {
                                return Math.floor(item.value)
                            })
                            var newOption = rsdata.map((item) => {
                                return item.time
                            })
                            var rNewData = newData.reverse()
                            var rOption = newOption.reverse()
                            _self.$nextTick(function () {
                                setTimeout(() => {
                                    var maxValue = Math.max(...rNewData.flat())
                                    var minValue = Math.min(...rNewData.flat())
                                    _self.chartOptions = {
                                        xaxis: {
                                            categories: rOption,
                                        },
                                        yaxis: {
                                            min: minValue * 0.9,
                                            max: maxValue * 1.1,
                                        },
                                    }
                                    _self.series[0].data = rNewData
                                }, 500)
                            })
                        } else {
                            _self.$vs.dialog({
                                color: 'danger',
                                title: _self.$t('WrongData'),
                                text: _self.$t('DataErrCannotDraw'),
                                accept: _self.acceptAlert,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log('personal 2 error ', e)
                        _self.$vs.loading.close()
                    })
                    .then(() => {
                        _self.popupActivChart = true
                        _self.$vs.loading.close()
                    })
            } else if (item.model == 'report') {
                axios
                    .get(process.env.VUE_APP_API_URL + '/admin/personalreport2_sub_value', {
                        params: {
                            pid: _self.userPid,
                            sub_id: item.sub_id,
                            s_time: temp_s_time,
                            e_time: this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 'OK') {
                            var rsdata = res.data.list
                            _self.tableData = rsdata
                            var newData = rsdata.map((item) => {
                                return item.value
                                // return Math.floor(item.value)
                            })
                            console.log(newData, rsdata)
                            var newOption = rsdata.map((item) => {
                                return item.time
                            })
                            var rNewData = newData.reverse()
                            var rOption = newOption.reverse()
                            _self.$nextTick(function () {
                                setTimeout(() => {
                                    var maxValue = Math.max(...rNewData.flat())
                                    var minValue = Math.min(...rNewData.flat())
                                    _self.chartOptions = {
                                        xaxis: {
                                            categories: rOption,
                                        },
                                        yaxis: {
                                            min: minValue * 0.9,
                                            max: maxValue * 1.1,
                                        },
                                    }
                                    _self.series[0].data = rNewData
                                }, 500)
                            })
                        }
                        console.log('report res', res)
                    })
                    .catch((e) => {
                        console.log('report 2 error ', e)
                        _self.$vs.loading.close()
                    })
                    .then(() => {
                        _self.popupActivChart = true
                        _self.$vs.loading.close()
                    })

                console.log('report chart')
            }
        },
        // 取現在時間
        getTime() {
            var dt = new Date()
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
        },
        // 判斷語系
        geti18n(items) {
            if (this.$i18n.locale == 'tw') {
                return items.tw
            } else if (this.$i18n.locale == 'cn') {
                return items.cn
            } else if (this.$i18n.locale == 'en') {
                return items.en
            }
        },
        // 取 生命健康數值
        getPersonSign() {
            var _self = this
            axios
                .get(process.env.VUE_APP_API_URL + '/admin/device_updatedata_by_pid/' + parseInt(_self.partnerid) + '/' + _self.pid)
                .then((res) => {
                    if (res.data.status == 'Success') {
                        var transfer_map = { sign1: 'vital_sign', sign2: 'metabolism_indicator', sign3: 'bodytype_indicator', check_up: 'check_up' }
                        Promise.all([
                            axios.get(process.env.VUE_APP_API_URL + '/admin/personalsign/' + _self.pid),
                            axios.get(process.env.VUE_APP_API_URL + '/admin/personalreport2_value', {
                                params: { main_id: 40, pid: _self.userPid },
                            }),
                        ])
                            .then((res) => {
                                return { ...res[0].data, ...res[1].data }
                            })
                            .then((jsonData) => {
                                var data = {}
                                for (const [key, newKey] of Object.entries(transfer_map)) {
                                    if (jsonData.hasOwnProperty(key)) {
                                        data[newKey] = jsonData[key]
                                    }
                                }
                                _self.lifeHealthData = data
                                Object.assign(_self.lifeHealthData, data)
                            })
                            .catch((err) => {
                                console.log('健康數據錯誤:', err)
                            })
                    } else {
                        console.log('amor err > ', res.data.message)
                    }
                })
                .catch((error) => {
                    console.log('health err ', error)
                })
                .then(() => {
                    _self.loadingFlag = false
                })
        },
        tag1(index) {
            console.log('tag', index)
        },
    },
}
</script>

<style>
.healthdata_list_card_item {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0 2rem;
}
</style>
