// import Axios from "axios"

import { saveSignByType } from '../api/user'

/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
    // COMPONENT
    // vx-autosuggest
    // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    windowBreakPoint: (state) => {
        // This should be same as tailwind. So, it stays in sync with tailwind utility classes
        if (state.windowWidth >= 1200) return 'xl'
        else if (state.windowWidth >= 992) return 'lg'
        else if (state.windowWidth >= 768) return 'md'
        else if (state.windowWidth >= 576) return 'sm'
        else return 'xs'
    },
    queryVipMembers: (state) => {
        let filtervipmembers = state.allvipmembers.filter((member) => member.name.toLowerCase().includes(state.vipMemberSearchQuery.toLowerCase()))
        return filtervipmembers
    },
    queryCallInfo: (state) =>
        state.callinfo.filter(() => {
            // let isItemOfCurrentFilter = false
            // if(state.callinfo === 'all'){}
        }),
    icd10all: (state) => state.icd10all,
    unreadmsg: (state) => state.unreadmsg,
    vipMemberSearchQuery: (state) => state.vipMemberSearchQuery,
    crmMemberSearchQuery: (state) => state.crmMemberSearchQuery,
    callinfofilterbydate: (state) => state.callinfofilterbydate,
    memberTotal: (state) => state.memberTotal,
    memberTotal2: (state) => state.memberTotal2,
    callInfoSearchQuery: (state) => state.callInfoSearchQuery,
    videoPath: (state) => state.videopath,
    token: (state) => state.token,
    avatar: (state) => {
        var data
        data = state.avatar
        return data
    },
    bankImage: (state) => state.bankImage,
    licenseImage: (state) => state.licenseImage,
    device: (state) => state.device,
    // devicetoken: state => state.devicetoken,
    showRightPanel: (state) => state.showRightPanel,
    personsign: (state) => state.personsign,
    queue: (state) => state.queue,
    queue1: (state) => {
        return state.queue.filter((info) => info.interval == 1)
    },
    queue2: (state) => {
        return state.queue.filter((info) => info.interval == 2)
    },
    queue3: (state) => {
        return state.queue.filter((info) => info.interval == 3)
    },
    queuetotal: (state) => state.queuetotal,
    queuenotfinishtotal: (state) => {
        return state.queuenotfinishtotal + state.agentReservationCount + state.otherDayReservationCount + state.specialReservationCount
    },
    profile: (state) => state.profile,
    doctortype: (state) => state.doctortype,
    doctorStatus: (state) => state.doctorStatus,
    division: (state) => state.division,
    divisionStrE: (state) => state.divisionStrE,
    experience: (state) => state.experience,
    alldoctors: (state) => state.alldoctors,
    allvipmembers: (state) => {
        return state.allvipmembers
    },
    allvipmembers2: (state) => {
        let filtervipmembers = state.allvipmembers2.filter((info) => info.name.toLowerCase().includes(state.vipMemberSearchQuery.toLowerCase()))
        return filtervipmembers
    },
    //  聯絡人
    contacts: (state) => state.contacts,
    //除了客服
    contactsNoService: (state) => {
        return state.contacts.filter((info) => info.staff_type != 4)
    },
    //全部
    contactsall: (state) => {
        var rs = _.sortBy(state.contactsall, [
            function (o) {
                return o
            },
        ])
        var data = _.orderBy(rs, ['online', 'mid'], ['desc', 'asc'])
        return data
    },
    name: (state) => state.name,
    playCallInSound: (state) => state.playCallInSound,
    playCallInSoundStatus: (state) => state.playCallInSoundStatus,
    sound: (state) => state.sound,
    callInMask: (state) => state.callInMask,
    callerName: (state) => state.callerName,
    callerPartnerEnterprise: (state) => state.callerPartnerEnterprise,
    wordS: (state) => state.wordS,
    wordO: (state) => state.wordO,
    wordA: (state) => state.wordA,
    wordP: (state) => state.wordP,
    wordMemo: (state) => state.wordMemo,
    wordNote: (state) => state.wordNote,
    wordWD: (state) => state.wordWD,
    wordDiet: (state) => state.wordDiet,
    notes: (state) => state.notes,
    servicelist: (state) => state.servicelist,
    planslist: (state) => state.planslist,
    roomList: (state) => state.roomList,
    myService: (state) => state.myService,
    selectedPatient: (state) => state.selectedPatient,
    myServiceLang: (state) => state.myServiceLang,
    myMedicalAllNurse: (state) => state.myMedicalAllNurse,
    chosenMedicalNurse: (state) => state.chosenMedicalNurse,
    chosenMedicaldoctor: (state) => state.chosenMedicaldoctor,
    callinfo: (state) => {
        if (state.filterCallInfoDate.start != null && state.filterCallInfoDate.end != null) {
            let filtercallinfo = state.callinfo.filter((info) => info.record_start_time >= state.filterCallInfoDate.start && info.record_start_time <= state.filterCallInfoDate.end)
            return filtercallinfo
        } else {
            let filtercallinfo = state.callinfo.filter((info) => info.name.toLowerCase().includes(state.callInfoSearchQuery.toLowerCase()) || info.tel.toLowerCase().includes(state.callInfoSearchQuery.toLowerCase()))
            return filtercallinfo
        }
    },
    callinfofilter: (state) => state.callinfofilter,
    chatContacts: (state) => {
        let chatcontacts = []
        if (state.chatSearchQuery !== '') {
            if (state.chatSelectedTab == 0) {
                chatcontacts = state.chatContacts.filter((contact) => contact.pname.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
            }
        } else {
            chatcontacts = state.chatContacts
        }
        return chatcontacts
    },
    chatContactsDoctor: (state) => {
        let chatcontacts = []
        if (state.chatSearchQuery !== '' && state.chatSelectedTab == 1) {
            chatcontacts = state.chatContactsDoctor.filter((contact) => contact.dname.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
        } else {
            chatcontacts = state.chatContactsDoctor
        }
        return chatcontacts
    },
    chatContactsGroup: (state) => {
        let chatcontacts = []
        if (state.chatSearchQuery !== '' && state.chatSelectedTab == 2) {
            if (state.chatContactsGroup) {
                chatcontacts = state.chatContactsGroup.filter((contact) => contact.name.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
            }
        } else {
            chatcontacts = state.chatContactsGroup
        }
        return chatcontacts
    },
    userEMRNormalPhoto: (state) => {
        return state.personalphoto
    },
    userEMREye: (state) => {
        return state.personaleye
    },
    userEMRSti: (state) => {
        return state.personalsti
    },
    userEMRDICOM: (state) => {
        return state.personaldicom
    },
    chatDataOfUser: (state) => state.chatDataOfUser,
    chatDataOfDoctor: (state) => state.chatDataOfDoctor,
    chatDataOfGroup: (state) => state.chatDataOfGroup,
    activeChatUser: (state) => state.activeChatUser,
    userEMRBaseData: (state) => state.userEMRBaseData,
    userEMRPersonSickFullData: (state) => state.userEMRPersonSickFullData,
    userEMRParentsSickFullData: (state) => state.userEMRParentsSickFullData,
    userEMRSensitiveMattersFullData: (state) => state.userEMRSensitiveMattersFullData,
    userEMRLifeTypeFullData: (state) => state.userEMRLifeTypeFullData,
    userEMRParentsCancerFullData: (state) => state.userEMRParentsCancerFullData,
    emrPersonSickData: (state) => state.emrPersonSickData,
    userEMRPersonSickData: (state) => {
        var tmpdata = []
        state.userEMRPersonSickFullData.forEach(function (v, k) {
            if (v.note != undefined) {
                if (v.note != null) {
                    tmpdata.push(v.note)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRPersonSickDataE: (state) => {
        var tmpdata = []
        state.userEMRPersonSickFullData.forEach(function (v, k) {
            if (v.ename != undefined) {
                if (v.ename != null) {
                    tmpdata.push(v.ename)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        if (state.userEMRPersonotherdiesaseother == null || state.userEMRPersonotherdiesaseother == '') {
            return tmpdata.toString().replace(/(^,)|(,$)/g, '')
        } else {
            return tmpdata.toString().replace(/(^,)|(,$)/g, '')
        }
    },
    userEMRParentsSickData: (state) => {
        var tmpdata = []
        state.userEMRParentsSickFullData.forEach(function (v, k) {
            if (v.note != undefined) {
                if (v.note != null) {
                    tmpdata.push(v.note)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRParentsSickDataE: (state) => {
        var tmpdata = []
        state.userEMRParentsSickFullData.forEach(function (v, k) {
            if (v.note != undefined) {
                if (v.note != null) {
                    tmpdata.push(v.ename)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },

    userEMRParentsCancerData: (state) => {
        var tmpdata = []
        state.userEMRParentsCancerFullData.forEach(function (v, k) {
            if (v.note != undefined) {
                if (v.note != null) {
                    tmpdata.push(v.note)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRParentsCancerDataE: (state) => {
        var tmpdata = []
        state.userEMRParentsCancerFullData.forEach(function (v, k) {
            if (v.ename != undefined) {
                if (v.ename != null) {
                    tmpdata.push(v.ename)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRSensitiveMattersData: (state) => {
        var tmpdata = []
        state.userEMRSensitiveMattersFullData.forEach(function (v, k) {
            if (v.note != undefined) {
                if (v.note != null) {
                    tmpdata.push(v.note)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRSensitiveMattersDataE: (state) => {
        var tmpdata = []
        state.userEMRSensitiveMattersFullData.forEach(function (v, k) {
            if (v.ename != undefined) {
                if (v.ename != null) {
                    tmpdata.push(v.ename)
                }
            } else if (v.mh_other_value != undefined) {
                if (v.mh_other_value != null) {
                    tmpdata.push(v.mh_other_value)
                }
            }
        })
        return tmpdata.toString().replace(/(^,)|(,$)/g, '')
    },
    userEMRLifeTypeData: (state) => {
        var tmpdata = []
        if (state.userEMRLifeTypeFullData[0] != null) {
            state.userEMRLifeTypeFullData.forEach(function (v) {
                tmpdata.push(v.note)
            })
        }
        return tmpdata.toString()
    },
    ringStatus: (state) => state.ringStatus,
    doctorSelectUnit: (state) => state.doctorSelectUnit,
    personBaseSign: (state) => state.personBaseSign,
    personSign2: (state) => state.personSign2,
    personSign3: (state) => state.personSign3,
    personSign4: (state) => state.personSign4,
    personSign5: (state) => state.personSign5,
    personSign6: (state) => state.personSign6,
    personSign7: (state) => state.personSign7,
    personSign8: (state) => state.personSign8,
    personSign9: (state) => state.personSign9,
    personSign10: (state) => state.personSign10,
    personSign11: (state) => state.personSign11,
    personSign12: (state) => state.personSign12,
    personSign13: (state) => state.personSign13,
    personSign14: (state) => state.personSign14,
    partnerEnterprise: (state) => state.partnerEnterprise,
    queryPartnerEnterprise: (state) => state.queryPartnerEnterprise,
    partner: (state) => state.partner,
    syncTriggle: (state) => state.syncTriggle,
    allHospital: (state) => state.allHospital,
    referHospital: (state) => state.referHospital,
    roomid: (state) => state.roomid,
    mrid: (state) => state.mrid,
    qid: (state) => state.qid,
    verify: (state) => state.verify,
    serviceUnit: (state) => state.serviceUnit,
    chatSelectedTab: (state) => state.chatSelectedTab,
    uiastaff: (state) => state.uiastaff,
    queuePmemo: (state) => state.queuePmemo,
    allemployees: (state) => state.allemployees,
    todolist: (state) => state.todolist,
    mytodolist: (state) => state.mytodolist,
    myopinion: (state) => state.myopinion,
    opinion: (state) => state.opinion,
    log: (state) => state.log,
    opinionState: (state) => state.opinionState,
    opinionId: (state) => state.opinionId,
    opinionType: (state) => state.opinionType,
    opinionAllData: (state) => state.opinionAllData,
    opinionStatus: (state) => state.opinionStatus,
    currentPid: (state) => state.currentPid,
    userUltraSound: (state) => state.userUltraSound,
    ectrocarDiogram: (state) => state.ectrocarDiogram,
    syncStatus: (state) => state.syncStatus,
    syncSwitch: (state) => state.syncSwitch,
    selectedSyncTab: (state) => state.selectedSyncTab,
    pauseSmallBtn: (state) => state.pauseSmallBtn,
    smsTime: (state) => state.smsTime,
    isConnected: (state) => state.isConnected,
    socketID: (state) => state.socketID,
    selectedicd10: (state) => state.selectedicd10,
    calling: (state) => state.calling,
    todayqueue: (state) => state.todayqueue,
    agentReservation: (state) => state.agentReservation,
    agentReservationCount: (state) => state.agentReservationCount,
    otherDayReservation: (state) => state.otherDayReservation,
    otherDayReservationCount: (state) => state.otherDayReservationCount,
    emergencyReservation: (state) => state.emergencyReservation,
    emergencyReservationCount: (state) => state.emergencyReservationCount,
    specialReservation: (state) => state.specialReservation,
    specialReservationCount: (state) => state.specialReservationCount,
    opinionActiveCount: (state) => state.opinionActiveCount,
    doctorEnable: (state) => state.doctorEnable,
    memoP: (state) => state.memoP,
    memoS: (state) => state.memoS,
    memoO: (state) => state.memoO,
    memoDiet: (state) => state.memoDiet,
    selectMemberDetail: (state) => state.selectMemberDetail,
    did: (state) => state.did,
    activePatientInfo: (state) => state.activePatientInfo,
    todoutotal: (state) => state.todoutotal,
    mytodoutotal: (state) => state.mytodoutotal,
    notifytotal: (state) => state.notifytotal,
    scheduletotal: (state) => state.scheduletotal,
    msgtotal: (state) => state.msgtotal,
    unFinishOpinion: (state) => state.unFinishOpinion,
    contactPatient: (state) => state.contactPatient,
    contactDoctor: (state) => state.contactDoctor,
    contactGroup: (state) => state.contactGroup,
    chatPatientCount: (state) => state.chatPatientCount,
    chatDoctorCount: (state) => state.chatDoctorCount,
    chatGroupCount: (state) => state.chatGroupCount,
    reloadPageBtnShow: (state) => state.reloadPageBtnShow,
    allmsgtotal: (state) => {
        return state.msgtotal + state.notifytotal
    },
    totalCountExceptMember: (state) => {
        return state.msgtotal + state.notifytotal + state.scheduletotal + state.todoutotal + state.opinionActiveCount
    },
    bioData: (state) => state.bioData,
    bioDataCodenamearray: (state) => state.bioDataCodenamearray,
    totalGroup: (state) => state.totalGroup,
    servicenotes: (state) => state.servicenotes,
    crmmembers: (state) => state.crmmembers,
    filterCrmmembers: (state) => {
        let tmpcrmmembers = []
        if (state.crmMemberSearchQuery != '') {
            tmpcrmmembers = state.crmmembers.filter((member) => member.patient_name.toLowerCase().includes(state.crmMemberSearchQuery.toLowerCase()))
        } else {
            tmpcrmmembers = state.crmmembers
        }
        return tmpcrmmembers
    },
    resverationAmNum: (state) => state.resverationAmNum,
    resverationPmNum: (state) => state.resverationPmNum,
    resverationNightNum: (state) => state.resverationNightNum,
    resverationOtherTimeNum: (state) => state.resverationOtherTimeNum,
    resverationAgentNum: (state) => state.resverationAgentNum,
    resverationGoHosTimeNum: (state) => state.resverationGoHosTimeNum,
    title: (state) => state.title,
    isDev: (state) => state.isDev,
    smsRoom: (state) => state.smsRoom,
    blocks: (state) => state.blocks,
    chatFirstClick: (state) => state.chatFirstClick,
    callinfototal: (state) => state.callinfototal,
    reservationViewModeType: (state) => state.reservationViewModeType,
    memberViewModeType: (state) => state.memberViewModeType,
    emrTabSetting: (state) => state.emrTabSetting,
    emrMedication: (state) => state.emrMedication,
    reservationTabSetting: (state) => state.reservationTabSetting,
    homeSetting: (state) => state.homeSetting,
    showSmallVideo: (state) => state.showSmallVideo,
    lifeHealthData: (state) => state.lifeHealthData,
    medicalTitleList: (state) => state.medicalTitleList,
    dietChartData: (state) => state.dietChartData,
    dateTime: (state) => {
        const year = state.now.getFullYear()
        const month = ('0' + (state.now.getMonth() + 1)).slice(-2)
        const day = ('0' + state.now.getDate()).slice(-2)
        const hours = ('0' + state.now.getHours()).slice(-2)
        const minutes = ('0' + state.now.getMinutes()).slice(-2)
        const seconds = ('0' + state.now.getSeconds()).slice(-2)
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        return formattedDateTime
    },
    date: (state) => {
        const year = state.now.getFullYear()
        const month = ('0' + (state.now.getMonth() + 1)).slice(-2)
        const day = ('0' + state.now.getDate()).slice(-2)
        const formattedDateTime = `${year}-${month}-${day}`
        return formattedDateTime
    },
    time: (state) => {
        const hours = ('0' + state.now.getHours()).slice(-2)
        const minutes = ('0' + state.now.getMinutes()).slice(-2)
        const seconds = ('0' + state.now.getSeconds()).slice(-2)
        const formattedDateTime = `${hours}:${minutes}:${seconds}`
        return formattedDateTime
    },
    appt_config: (state) => (state.ui_config && state.ui_config.appt ? state.ui_config.appt : null),
    emr_config: (state) => (state.ui_config && state.ui_config.emr ? state.ui_config.emr : null),
    login_config: (state) => (state.ui_config && state.ui_config.login ? state.ui_config.login : null),
    loginRouter_config: (state) => (state.ui_config && state.ui_config.loginRouter ? state.ui_config.loginRouter : null),
    logo_config: (state) => (state.ui_config && state.ui_config.logo ? state.ui_config.logo : null),
    menu_config: (state) => (state.ui_config && state.ui_config.menu ? state.ui_config.menu : null),
    remoteAppt_config: (state) => (state.ui_config && state.ui_config.remoteAppt ? state.ui_config.remoteAppt : null),
}

export default getters
