<template>
  <!-- 聊天室 -->
  <div id="chat-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <!-- 左半邊 -->
    <vs-sidebar class="items-no-padding" parent="#chat-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isChatSidebarActive" id="chat-list-sidebar">
      <!-- 搜尋聯絡人 -->
      <div class="chat__profile-search flex p-4">
        <vs-input icon-no-border icon="icon-search" icon-pack="feather" class=" w-full mx-5 input-rounded-full " :placeholder="$t('chat.searchContact') + '...'" v-model="searchQuery"/>
        <feather-icon class="md:inline-flex lg:hidden -ml-3 cursor-pointer" icon="XIcon" @click="toggleChatSidebar(false)" />
      </div>
      <vs-divider class="d-theme-border-grey-light m-0" />
      <VuePerfectScrollbar class="chat-scroll-area py-1" :settings="settings" ref="chatLeftSideScrollbar">
        <!-- ACTIVE CHATS LIST -->
        <div class="chat__chats-list mb-8" >
          <el-tabs ref="tabs" stretch v-model="activeName" @tab-click="handleClickTab">
            <!-- 民眾 -->
            <el-tab-pane name="first">
              <span class="mb-0 text-base" slot="label">{{$t('chat.patient')}}<vs-chip color="danger" class="mt-3" style="height: 10px;" v-show="chatPatientCount!=0">{{chatPatientCount}}</vs-chip></span>
              <ul class="chat__active-chats bordered-items">
                <li ref="chatContacts" style="height:5rem;padding:1rem" class="cursor-pointer" :class="{'active':contact.selected}" :id="'list_'+index" v-for="(contact, index) in chatContacts" :key="index" @click="updateActiveChatUser(contact.pmid, index, contact.pname)">
                  <div class="contact__container w-full flex items-center justify-between overflow-hidden">
                      <div class="contact__info flex flex-col truncate w-5/6">
                        <span class=" ml-2" :class="{'active':contact.selected}"><vs-chip color="danger" class="mr-3" v-if="contact.unread">{{contact.unread}}</vs-chip> {{contact.pname}}</span>
                        <p class=" ml-2 " style="font-size:0.85rem">{{ contact.data.msg_txt.replaceAll('&lt;br /&gt;',' ') | trim50}}</p>
                      </div>
                      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                        <p class="whitespace-no-wrap" style="font-size:10px;">{{contact.data.date_time}}</p>
                      </div>
                  </div>
                </li>
                <!-- 沒有更多 -->
                <div class="contact__container" style="padding:1rem auto;text-align:center;background:#e8ecef;">
                  <br>
                  <p class="mb-0 text-center" slot="label" style="text-base;  color:dark;" v-if="leftbottommessage==0 & chatContacts=='' ">...{{ $t('chat.noContact') }}...</p>
                  <p class="mb-0 text-center" slot="label" style="text-base;  color:dark;" v-if="leftbottommessage==1">...{{ $t('chat.noMore') }}...</p>
                  <p class="mb-0 text-center" slot="label" style="text-base;  color:dark;" v-if="leftbottommessage==0 & chatContacts!=''">...{{ $t('chat.loading') }}...</p>
                  <br>
                </div>
              </ul>
            </el-tab-pane>
            <!-- 醫事人員 -->
            <el-tab-pane name="second">
              <span slot="label" class="text-base">{{$t('chat.medicalStaff')}}<vs-chip color="danger"  v-show="chatDoctorCount!=0">{{chatDoctorCount}}</vs-chip></span>
              <div class="mb-4 flex justify-center">
                <vs-button  color="primary" type="border" icon="add" @click="addNewDoctorChatShow">{{$t('chat.addMRStaff')}}</vs-button>
              </div>
              <ul class="chat__active-chats bordered-items">
                <li ref="chatContactsDoctor" style="height:5rem;padding:1rem" class="cursor-pointer" :class="{'active':position === index2}" v-for="(doctor, index2) in chatContactsDoctor" :key="'D'+index2" @click="updateActiveChatDoctor(doctor.dmid, index2, doctor.dname)">
                  <div class="contact__container w-full flex items-center justify-between overflow-hidden">
                    <div class="contact__info flex flex-col truncate w-5/6">
                      <span class=" ml-2" :class="{'active':position === index2}"><vs-chip color="danger" class="mr-3" v-if="doctor.unread">{{doctor.unread}}</vs-chip> {{doctor.dname}}</span>
                      <p class=" ml-2" style="font-size:0.85rem">{{ doctor.data.msg_txt.replaceAll('&lt;br /&gt;',' ') | trim50}}</p>
                    </div>
                    <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                      <p class="whitespace-no-wrap" style="font-size:10px;">{{doctor.data.date_time}}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <!-- 群組 -->
            <el-tab-pane name="third">
              <span slot="label" class="text-base">{{$t('chat.group')}}<vs-chip color="danger" class="mt-3" style="height: 10px;" v-show="chatGroupCount!=0">{{chatGroupCount}}</vs-chip></span>
              <div class="mb-4 flex justify-center">
                <vs-button class="cd-button-queue-member" color="primary" type="border" icon="add" @click="popupActivo2=true">{{$t('chat.addGroup')}}</vs-button>
              </div>
              <ul class="chat__active-chats bordered-items">
                <li ref="chatContactsGroup" style="height:5rem;padding:1rem" class="cursor-pointer" :class="{'active':gposition === index3}" v-for="(group, index3) in chatContactsGroup" :key="'X'+index3" @click="updateActiveChatGroup(group.msg_group_id, index3, group.name)">
                  <div class="contact__container w-full flex items-center justify-between overflow-hidden">
                    <div class="contact__info flex flex-col truncate w-5/6">
                      <span class=" my-1 ml-2" :class="{'active':gposition === index3}"><vs-chip color="danger" class="mr-3" v-if="group.unread">{{group.unread}}</vs-chip> {{group.name}}</span>
                      <p class=" ml-2" style="font-size:0.85rem">{{ group.lasttxt.replaceAll('&lt;br /&gt;',' ') | trim50}}</p>
                    </div>
                    <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
                      <p class="whitespace-no-wrap" style="font-size:10px;">{{group.lasttime}}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- 沒有群組 -->
              <div v-show="nogroup">{{$t('chat.noGroup')}}!</div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </VuePerfectScrollbar>
    </vs-sidebar>
    <!-- 右半邊 -->
    <div class="chat__bg app-fixed-height chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0" :class="{'sidebar-spacer--wide': clickNotClose, 'flex items-center justify-center': activeChatUser === null}">
      <!-- navbar -->
      <div class="chat__navbar">
        <chat-navbar v-if="activeChatUser" :isSidebarCollapsed="!clickNotClose" :userId="activeChatUser" :tabId="selectedTab" @openContactsSidebar="toggleChatSidebar(true)"></chat-navbar>
      </div>
      <!-- 訊息 -->
      <chat-log :userId="activeChatUser" :tabId="selectedTab" v-if="activeChatUser" :chatRoomChange="chatRoomChange"></chat-log>
      <!-- 發送訊息 -->
      <div class="chat__input flex bg-white" v-if="activeChatUserId">
        <importSetWord :dataType="'wd/all'" :uiType="'icon'" @importMsg="importMsg($event)"></importSetWord>
        <vs-input
          v-if="activeChatUser!=null"
          class="inputDeep flex-1 text-base"
          ref="msgInput"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 4 }"
          resize="none"
          :placeholder="$t('chat.sendMsg') + '...'"
          v-model="typedMessage"
          @keydown.enter.exact.prevent.native
          @keydown.shift.enter.exact.prevent.native
          @keyup.enter.exact.native="sendMsg"
          @keyup.shift.enter.exact.native="lineFeed()" />
        <vs-button class="bg-primary-gradient" @click="sendMsg" v-if="activeChatUser!=null">{{$t('chat.submit')}}</vs-button>
      </div>
    </div>
    <!-- 新增群組 popup -->
    <vs-popup classContent="popup-example " :title="$t('chat.addGroup')" :active.sync="popupActivo2">
      <div class="md:inline-flex">
        <div class="flex cd-button-group">
          <vs-input class="inputx" v-model="groupName" :placeholder="$t('chat.groupName')"/>
          <vs-button class="ml-2 cd-button-2w" @click="addGroup">{{$t('popup.add')}}</vs-button>
        </div>
      </div>
      <VuePerfectScrollbar class="chat-content-scroll-area" :settings="settings">
        <vs-list>
          <vs-list-header :title="$t('chat.medicalStaff')"></vs-list-header>
            <vs-list-item :title="doctor.dname" v-for="(doctor, j) in chatContactsDoctor" :key="'DG'+j">
              <vs-checkbox color="danger" :vs-value="doctor.dmid" v-model="selectedGroupDoctor"/>
            </vs-list-item>
          <vs-list-header :title="$t('chat.patient')"></vs-list-header>
            <vs-list-item :title="user.pname" v-for="(user, k) in chatContacts" :key="'A'+k">
              <vs-checkbox color="danger" :vs-value="user.pmid" v-model="selectedGroupUser"/>
            </vs-list-item>
        </vs-list>
      </VuePerfectScrollbar>
    </vs-popup>
    <!-- 新增醫事人員 popup -->
    <vs-popup classContent="popup-example" :title="$t('chat.addMRStaff')" :active.sync="popupActivo3">
      <div>
        <vs-row>
          <vs-col vs-type="flex" vs-align="center">
            <vs-input class="inputx" v-on:keyup.13="searchDoctorBtn" :placeholder="$t('chat.searchMRStaff')" v-model="querydoctor"/>
            <vs-button class="ml-2 cd-button-2w" @click="saveNewDoctorChatBtn">{{$t('popup.add')}}</vs-button>
          </vs-col>
        </vs-row>
        <vs-row class="mt-3">
          <vs-col>
            <VuePerfectScrollbar class="chat-content-scroll-area" style="height: 500px;" :settings="settings">
              <ul class="centerx">
                <li v-if="searchDoctorList!=0">
                  <vs-checkbox v-model="selectedNewDoctor" class="mt-1" :vs-value="item" v-for="(item, index) in searchDoctorList" :key="index">{{item.family_name}}{{item.name}}</vs-checkbox>
                </li>
                <li class="mt-2 ml-3" v-else>{{$t('chat.noMRStaff')}}!</li>
              </ul>
            </VuePerfectScrollbar>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChatLog             from './ChatLog.vue'
import ChatNavbar          from './ChatNavbar.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import importSetWord       from '@/components/importSetWord'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { messageAddGroup, searchDoctor, saveNewDoctorChat, getCountByUnreadMsg, updateBadge }   from "@/api/user"
import { fetchChatContacts,fetchDoctorListByPartner,QueryDoctorListByPartner } from '@/api/user'

export default {
  data() {
    return {
      activeName: 'first',
      querydoctor: '',
      groupName: '',
      nogroup: false,
      selectedNewDoctor: [],
      selectedGroupDoctor: [],
      selectedGroupUser: [],
      searchDoctorList: [],
      popupActivo2: false,
      popupActivo3: false,
      active               : true,
      searchContact        : "",
      activeChatUserId     : -1,
      typedMessage         : "",
      settings             : {
        maxScrollbarLength : 20,
        wheelSpeed         : 0.70,
      },
      clickNotClose        : true,
      isChatSidebarActive  : true,
      position: 0,
      gposition: 0,
      leftSideScrollbarElement: [],
      nextpage: 1,//左半邊往上滑動
      nodataFlag: 0,
      leftbottommessage:0,
      chatRoomChange:0,
    }
  },
  filters: {
    trim50(txt) {
      let result
      if(txt){
        if(txt.length > 15){
          result = txt.substring(0, 15) + '...'
          return result
        }else{
          return txt
        }
      }else{
        return ''
      }
    }
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth()
    },
    //監控是否有websocket
    chatContactswebsocket(){
      this.nodataFlag = 0
      this.nextpage = 0
    },
    // 自動搜尋醫事人員
    querydoctor(newValue) {
      var _self = this
      var payload = {
        did:this.did,
        partnerid:localStorage.getItem('partnerid'),
        query: newValue
      }
      _self.$vs.loading()
      QueryDoctorListByPartner(payload).then(res => {
        if(res.data.status=='OK'){
          _self.searchDoctorList = res.data.data
        }
        _self.$vs.loading.close()
      })
    }
  },
  created() {
    this.setSidebarWidth()
  },
  async mounted() {
    var _self = this
    await _self.$store.dispatch('fetchChatContacts').then(() => {
      _self.$store.dispatch('fetchChatContactsDoctor')
      if(_self.$refs.chatContacts){
        _self.$refs.chatContacts[0].click()
      }
    })
    _self.$root.$on('updateContact', () =>{
      if(_self.$refs.chatContactsDoctor){
        _self.$refs.chatContactsDoctor[0].click()
      }
    })
    _self.$root.$on('updateContactGroup', () =>{
      if(_self.$refs.chatContactsGroup){
        _self.$refs.chatContactsGroup[0].click()
      }
    })
    _self.leftSideScrollbarElement = _self.$refs.chatLeftSideScrollbar.$el
    _self.leftSideScrollbarElement.addEventListener('scroll', _self.leftSideScrollbarListener)
  },
  computed: {
    ...mapState(['partner_id']),
    chatContactswebsocket(){
      return this.$store.state.chatContactswebsocket
    },
    chatFirstClick() {
      if(this.$store.getters.chatFirstClick){
        this.$refs.chatContacts[this.position].click()
        this.$store.commit('SET_CHAT_FIRST_CLICK', false)
      }
      return this.$store.getters.chatFirstClick
    },
    totalCountExceptMember() {
      return this.$store.getters.totalCountExceptMember
    },
    contacts: {
      get() {
        return this.$store.getters.contactsall
      },
    },
    selectedTab: {
      get() {
        return this.$store.getters.chatSelectedTab
      },
      set(val) {
        this.$store.commit('SET_CHAT_SELECTED_TAB', val)
      }
    },
    chatPatientCount() {
      return this.$store.state.chatPatientCount
    },
    did() {
            return this.$store.getters.did
    },
    chatDoctorCount() {
      return this.$store.state.chatDoctorCount
    },
    chatGroupCount() {
      return this.$store.state.chatGroupCount
    },
    contactGroup() {
      return this.$store.state.contactGroup
    },
    chatContacts: {
      get() {
        return this.$store.getters.chatContacts
      },
      set(val) {
        this.$store.commit('SET_UNREAD', val)
      }
    },
    chatContactsDoctor() {
      return this.$store.getters.chatContactsDoctor
    },
    chatContactsGroup() {
      return this.$store.getters.chatContactsGroup
    },
    searchQuery: {
      get() {
        return this.$store.state.chatSearchQuery
      },
      set(val) {
        this.$store.dispatch('setChatSearchQuery', val)
      }
    },
    activeChatUser: {
      get() {
        return this.$store.getters.activeChatUser
      },
      set() {}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },
  methods: {
    // 取未讀訊息數量
    getCountByUnreadMsg() {
      var _self = this
      getCountByUnreadMsg().then(res => {
        if(res.data.status=='OK'){
          _self.$store.commit('SET_MSG_UNFINISH_TOTAL',res.data.count)
        }
      }).catch((error) => {
        console.log('msg count : '+error)
      })
    },
    // 選擇 民眾/醫事人員/群組 後，點擊第一個打開聊天室
    handleClickTab(tab) {
      var _self = this
      var num = parseInt(tab.index)
      _self.selectedTab = num
      if(num==2){
        _self.$store.dispatch('fetchChatContactsGroup').then(() => {
          _self.$refs.chatContactsGroup[0].click()
        })
      }else if(num==1){
        _self.$store.dispatch('fetchChatContactsDoctor').then(() => {
          _self.$refs.chatContactsDoctor[0].click()
        })
      }else if(num==0){
        _self.$store.dispatch('fetchChatContacts').then(() => {
          _self.$refs.chatContacts[0].click()
        })
      }
    },
    // 搜尋醫事人員
    searchDoctorBtn() {
      var _self = this
      var payload = {
        did:this.did,
        partnerid:localStorage.getItem('partnerid'),
        query: _self.querydoctor
      }
      console.log(payload)
      _self.$vs.loading()
      QueryDoctorListByPartner(payload).then(res => {
        if(res.data.status=='OK'){
          console.log("search",res)
          _self.searchDoctorList = res.data.data
        }
        _self.$vs.loading.close()
      })
    },
    // 新增醫事人員
    saveNewDoctorChatBtn() {
      var _self = this
      saveNewDoctorChat({'newdoctor': _self.selectedNewDoctor}).then(res => {
        if(res.data.status=='OK'){
          _self.$store.dispatch('fetchChatContactsDoctor').then(res => {
            _self.updateActiveChatDoctor(res.data.item[0].data.from_mid, 0, res.data.item[0].data.name)
          })
        }else{
          _self.notify('danger', _self.$t('message.create_error'), '')
        }
        _self.querydoctor = ''
        _self.selectedNewDoctor = []
        _self.popupActivo3 = false
      })
    },
    // 開啟 新增醫事人員 視窗
    addNewDoctorChatShow() {
      this.popupActivo3 = true
      this.searchDoctorBtn();
    },
    // 新增群組
    addGroup() {
      var _self = this
      var newSelectedGroup = []
      if(_self.groupName==''){
        alert(_self.$t('chat.enterGroupName'))
        return false
      }
      if(_self.selectedGroupDoctor.length==0 && _self.selectedGroupUser.length==0){
        alert(_self.$t('chat.selectGroupMember'))
        return false
      }
      if(_self.selectedGroupDoctor.length!=0){
        _self.selectedGroupDoctor.forEach(function(v){
          newSelectedGroup.push({'type': 1, 'to_mid': v});
        })
      }
      if(_self.selectedGroupUser.length!=0){
        _self.selectedGroupUser.forEach(function(v){
            newSelectedGroup.push({'type': 0, 'to_mid': v});
        })
      }
      messageAddGroup({'title': _self.groupName, 'list': newSelectedGroup}).then(res => {
        if(res.data.status=='OK'){
          _self.$store.dispatch('fetchChatContactsGroup').then(res2 => {
            _self.$store.commit('SET_CHAT_CONTACT_GROUP', res2)
            _self.updateActiveChatGroup(res2[0].msg_group_id, 0, res2[0].name)
            _self.$refs.chatContactsGroup[0].click()
            _self.notify('grey', _self.$t('message.create_success'), '')
          })
        }else{
          _self.notify('danger', _self.$t('message.create_error'), '')
        }
      })
      _self.groupName = ''
      _self.selectedGroupUser = []
      _self.selectedGroupDoctor = []
      _self.popupActivo2 = false
    },
    // 選擇民眾聊天室 (待整理)
    updateActiveChatUser(contactId, index, name) {
      var _self = this
      var num = _self.selectedTab
      _self.position = index
      var payload = {
        kind: _self.selectedTab,
        usermid: contactId,
        uname: name,
        position: index,
      }
      // 群組
      if(num==2){
        _self.$store.dispatch('setActiveChatUser', payload)
        _self.activeChatUser = payload
        _self.activeChatUserId = contactId
        // 取訊息
        _self.$vs.loading()
        _self.$store.dispatch('markSeenMessagesOfUser', {'pmid': contactId, 'kind': _self.selectedTab}).then(() => {
          _self.$vs.loading.close()
        })
      }
      // 民眾
      else if(num==0){
        var tmpchatContacts = _.cloneDeep(_self.chatContacts)
        _.forEach(tmpchatContacts, function(v, i){
          if(i==_self.position){
            v.selected = true
            v.unread = 0
          }else{
            v.selected = false
          }
        })
        _self.$store.commit('SET_CHAT_CONTACT', tmpchatContacts)
        _self.$store.dispatch('setActiveChatUser', payload)
        _self.activeChatUser = payload
        _self.activeChatUserId = contactId
        _self.$store.commit('SET_CHAT_PATIENT_COUNT', 0)
        // 取訊息
        _self.$vs.loading()
        _self.$store.dispatch('markSeenMessagesOfUser', {'pmid': contactId, 'kind': _self.selectedTab}).then(() => {
          _self.getCountByUnreadMsg()
          _self.$vs.loading.close()
        })
      }
      _self.toggleChatSidebar()
      _self.typedMessage = ''
      this.chatRoomChange++
    },
    // 選擇醫事人員聊天室
    updateActiveChatDoctor(contactId, index, name) {
      this.position = index
      var payload = {
        kind: this.selectedTab,
        usermid: contactId,
        uname: name,
        position: index,
      }
      this.$store.dispatch('setActiveChatUser', payload)
      this.activeChatUser = payload
      this.activeChatUserId = contactId
      this.chatContactsDoctor[this.position].unread = 0
      this.$store.commit('SET_CHAT_DOCTOR_COUNT', 0)
      this.$store.commit('SET_MSG_UNFINISH_TOTAL', 0)
      this.$vs.loading()
      this.$store.dispatch('markSeenMessagesOfDoctor', {'dmid2': contactId, 'kind': this.selectedTab}).then(() => {
      }).catch((error) => {
        console.log('make msg ERR:: '+error)
      }).then(() => {
        this.$vs.loading.close()
        this.toggleChatSidebar()
        this.typedMessage = ''
      })
      this.chatRoomChange++
    },
    // 選擇群組聊天室
    updateActiveChatGroup(gid, index, name){
      this.gposition = index
      var payload = {
        kind: this.selectedTab,
        usermid: gid,
        uname: name,
        position: index,
      }
      this.$store.dispatch('setActiveChatUser', payload)
      this.activeChatUser = payload
      this.activeChatUserId = gid
      this.chatContactsGroup[this.gposition].unread = 0
      this.$store.commit('SET_CHAT_GROUP_COUNT', 0)
      this.$store.commit('SET_MSG_UNFINISH_TOTAL', 0)
      this.$store.dispatch('markSeenMessagesOfGroup', {'gid': gid, 'kind': this.selectedTab}).then(() => {
        this.toggleChatSidebar()
      })
      this.chatRoomChange++
    },
    // 發送訊息
    async sendMsg() {
      if (!this.typedMessage) return
      this.typedMessage = this.typedMessage.replace(/\n/g,"<br />");
      var mytype = 'member'
      if(this.selectedTab==2){
        mytype = 'group'
      }
      if(this.activeChatUserId=='-1' || this.activeChatUserId==0){
        this.$alert(this.$t('chat.reselectUser'), this.$t('chat.selectUser'), {
          confirmButtonText: this.$t('popup.confirm'),
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
          }
        });
      }
      const payload = {
        isPinned: true,
        'msg': {
          'textContent' : this.typedMessage,
          'time'        : '', //資料庫會自動新增現在時間(CURRENT_TIMESTAMP)
          'isSent'      : true, //發送
          'isSeen'      : false, //看過
        },
        'id': this.activeChatUserId,
        'to_mid_type': this.selectedTab,
        'msg_group_id': 0,
        'type': mytype,
        'partner_id': this.partner_id
      }
      await this.$store.dispatch('sendChatMessage', payload).then((res) => {
        if(res.data.status=='OK'){
          if(this.selectedTab==0){
            this.$store.dispatch('fetchChatContacts').then(()=>{
              this.nodataFlag = 0
              this.nextpage = 0
              this.$refs.chatContacts[0].click()
            })
          }else if(this.selectedTab==1){
            this.$store.dispatch('fetchChatContactsDoctor').then(()=>{
              this.$refs.chatContactsDoctor[0].click()
            })
          }else if(this.selectedTab==2){
            this.$store.dispatch('fetchChatContactsGroup').then(()=>{
              this.$refs.chatContactsGroup[0].click()
            })
          }
        }else{
          _self.notify('danger', _self.$t('message.update_error'), res.data.message)
        }
        this.typedMessage = ''
      })
    },
    // 設定左半邊sidebar是否動態顯示
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.clickNotClose = false
      } else {
        this.clickNotClose = true
      }
    },
    // 開關 左半邊sidebar
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    },
    // 左半邊進行滑動觸發
    leftSideScrollbarListener() {
      var leftSideScrollTop = this.leftSideScrollbarElement.scrollTop
      var leftSideScrollHeight = this.leftSideScrollbarElement.scrollHeight
      var leftSideScrollclientHeight = this.leftSideScrollbarElement.clientHeight
      var bottomOfLeftSideScrollbarElement = leftSideScrollHeight - leftSideScrollTop - leftSideScrollclientHeight
      if (bottomOfLeftSideScrollbarElement <= 1 && this.nodataFlag==0) {
        this.$nextTick(() => {
          this.nextpage++
          this.fetchcontact3List(this.nextpage)
        },1000)
      }
    },
    // 左半邊新增資料
    fetchcontact3List(nextpage) {
      fetchChatContacts(nextpage).then((resp) => {
        var _self = this
        var tmpdata = resp.data.item
        if (tmpdata.length != 0){
          var tmpchatContacts = _.sortBy(resp.data.item, ['data.date_time']).reverse();
          tmpchatContacts = _.slice(tmpchatContacts,(_self.nextpage-1)*8,(_self.nextpage-1)*8+8)
          // 沒有更多聯絡人
          if(tmpchatContacts.length==0){
            _self.nodataFlag = 1
            _self.nextpage = 1
            _self.leftbottommessage = 1
          }
          // 載入中
          if(tmpchatContacts.length!=0){
            _self.leftbottommessage = 0
          }
          tmpchatContacts = _.unionBy(_self.chatContacts,tmpchatContacts,'pmid')
          _self.$store.commit('SET_CHAT_CONTACT', tmpchatContacts)
        }
      })
    },
    // 換行
    lineFeed () {
			this.typedMessage = this.typedMessage + '\n'
		},
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
    // 匯入常用字句
    importMsg(msg){
      // 判斷最後一個字元是不是換行符號
      let lastWord = this.typedMessage.split("")[this.typedMessage.split("").length-1]
      if(lastWord != '\n' && lastWord != undefined) {
        this.typedMessage = this.typedMessage + '\n'
      }
      this.typedMessage += msg
    },
  },
  components: {
    ChatNavbar,
    ChatLog,
    VuePerfectScrollbar,
    PerfectScrollbar,
    importSetWord
  },
  beforeDestroy: function() {
    this.$store.dispatch('setActiveChatUser', {})
    updateBadge(this.totalCountExceptMember)
  },
  destroyed() {
    this.leftSideScrollbarElement.removeEventListener('scroll', this.leftSideScrollbarListener)
  },
}
</script>
<style lang="css">
/* 選取對話聯絡人 */
  .active {
    background-color: #e8ecef;
    color: #111;
  }
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>