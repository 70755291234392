import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    name: 'home', //儀錶板
                    component: () => import('./views/Home.vue'),
                },
                // {
                //   path: '/reservation', //掛號管理
                //   name: 'Reservation',
                //   component: () => import('./views/Reservation.vue')
                // },
                {
                    path: '/members', //會員管理
                    name: 'Members',
                    component: () => import('./views/Members.vue'),
                },
                {
                    path: '/membersmgmt', //會員管理
                    name: 'Membersmgmt',
                    component: () => import('./views/MembersMgmt.vue'),
                },
                {
                    path: '/appthistory', //掛號紀錄
                    name: 'ApptHistory',
                    component: () => import('./views/ApptHistory.vue'),
                },
                {
                    path: '/message/chat',
                    name: 'Chat',
                    component: () => import('./views/apps/chat/index.vue'),
                    meta: {
                        no_scroll: true,
                    },
                },
                {
                    path: '/message/notify',
                    name: 'Notify',
                    component: () => import('./views/message/notify.vue'),
                },
                {
                    path: '/message/schedule',
                    name: 'Schedule',
                    component: () => import('./views/message/schedule.vue'),
                },
                {
                    path: '/todolist',
                    name: 'TodoList',
                    component: () => import('./views/todolist.vue'),
                },
                {
                    path: '/opinion',
                    name: 'Opinion',
                    component: () => import('./views/Opinion.vue'),
                },
                {
                    path: '/authorization',
                    name: 'Authorization',
                    component: () => import('./views/Authorization.vue'),
                },
                {
                    path: '/emr2/:pid/:qid',
                    name: 'EMR2',
                    component: () => import('./views/Emr.vue'),
                },
                {
                    path: '/doctornote/:qid',
                    name: 'DoctorNote',
                    component: () => import('./views/Doctornote.vue'),
                },
                {
                    path: '/funduscopy/:eyeid/:caseid?',
                    name: 'Funduscopy',
                    component: () => import('./views/Funduscopy.vue'),
                },
                {
                    path: '/histemplate/:opinionfilterid/:caseid',
                    name: 'Histemplate',
                    component: () => import('./views/Histemplate.vue'),
                },
                {
                    path: '/viewdicom/:dicomid',
                    name: 'Dicom',
                    component: () => import('./views/ViewDicom.vue'),
                },
                {
                    path: '/setting/teach_install',
                    name: 'teach_install',
                    component: () => import('./views/setting/teach_install.vue'),
                },
                {
                    path: '/setting/shift',
                    name: 'Shift',
                    component: () => import('./views/setting/shift.vue'),
                },
                {
                    path: '/setting/contact',
                    name: 'Contact',
                    component: () => import('./views/setting/contact.vue'),
                },
                {
                    path: '/setting/service',
                    name: 'Service',
                    component: () => import('./views/setting/service.vue'),
                },
                {
                    path: '/setting/setword',
                    name: 'SetWord',
                    component: () => import('./views/setting/setword.vue'),
                },
                {
                    path: '/setting/upload/:pid?',
                    name: 'Upload',
                    component: () => import('./views/setting/upload.vue'),
                },
                {
                    path: '/upload_select',
                    name: 'Upload_select',
                    component: () => import('./views/upload_select.vue'),
                },
                {
                    path: '/setting/profile',
                    name: 'Profile',
                    component: () => import('./views/setting/profile.vue'),
                },
                {
                    path: '/setting/privacy',
                    name: 'Privacy',
                    component: () => import('./views/setting/privacy.vue'),
                },
                {
                    path: '/setting/log',
                    name: 'Log',
                    component: () => import('./views/setting/log.vue'),
                },
                {
                    path: '/monitor',
                    name: 'Monitor',
                    component: () => import('./views/Monitor.vue'),
                },
                {
                    path: '/crm',
                    name: 'CRM',
                    component: () => import('./views/serviceRecord.vue'),
                },
                {
                    path: '/apptmgmt/:agent?',
                    name: 'ApptMgmt',
                    component: () => import('./views/ApptMgmt.vue'),
                },
                {
                    path: '/dietMgmt',
                    name: 'dietMgmt',
                    component: () => import('./views/dietMgmt.vue'),
                },
                {
                    path: '/dietReport/:pid',
                    name: 'dietReport',
                    component: () => import('./views/dietReport.vue'),
                },
                {
                    path: '/calllog',
                    name: 'calllog',
                    component: () => import('./views/CallLog.vue'),
                },
                {
                    path: '/housedemo',
                    name: 'housedemo',
                    component: () => import('./views/housedemo.vue'),
                },
                {
                    path: '/freeclinic',
                    name: 'freeclinic',
                    component: () => import('./views/freeclinic.vue'),
                },
                {
                    path: '/tb',
                    name: 'tb',
                    component: () => import('./views/Tb.vue'),
                },
                {
                    path: '/questionsmgmt',
                    name: 'questions',
                    component: () => import('./views/QuestionsMgmt.vue'),
                },
                {
                    path: '/screening',
                    name: 'screening',
                    component: () => import('./views/Screening.vue'),
                },
            ],
        },
        // {
        //   path: '/login',
        //   name: 'login',
        //   beforeEnter(to, from, next) {
        //     // Put the full page URL including the protocol http(s) below
        //     window.location.href = "https://example.com"
        //   }
        // },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/pages/login',
                    name: 'page-login',
                    component: () => import('@/views/pages/Login.vue'),
                },
                {
                    path: '/pages/monitor',
                    name: 'page-monitor',
                    component: () => import('@/views/pages/monitor.vue'),
                },
                {
                    path: '/pages/forgetpassword',
                    name: 'page-forgetpassword',
                    component: () => import('@/views/pages/ForgetPassword.vue'),
                },
                {
                    path: '/pages/register',
                    name: 'page-register',
                    component: () => import('@/views/pages/Register.vue'),
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                },
                {
                    path: '/freeclinic_print/:eventid/:pid',
                    name: 'freeclinic_print',
                    component: () => import('./views/pages/freeclinic_print.vue'),
                },
                {
                    path: '/these-auto-login',
                    name: 'theseAutoLogin',
                    component: () => import('@/views/TheseAutoLogin.vue'),
                },
            ],
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404',
        },
    ],
})
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
