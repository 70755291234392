<template>
    <div>
        <div v-if="roomList.length > 0">
            <RoomItem v-for="(room, index) in roomList" :key="index" :data="room" @close="close"></RoomItem>
        </div>
        <div class="p-4 text-center" v-else>沒有房間</div>
    </div>
</template>
<script>
import RoomItem from '@/components/Room/RoomItem.vue'
import { mapState } from 'vuex'
export default {
    components: { RoomItem },
    computed: {
        ...mapState(['pm_id', 'roomList']),
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>
