<template>
    <div class="grid grid-cols-1 gap-8">
        <div>
            <div class="flex flex-wrap justify-evenly items-center">
                <!-- 用於顯示攝像頭視頻 -->
                <video class="w-64 border border-solid border-grey" ref="video" autoplay></video>
                <vs-button class="m-8 rounded whitespace-no-wrap" @click="takePhoto">掃描</vs-button>
            </div>
            <!-- 用於顯示拍照結果 -->
            <canvas ref="canvas" style="display: none"></canvas>
        </div>
        <div class="grid grid-cols-1 gap-4">
            <vs-input size="large" label="* 姓名" class="w-auto text-primary" v-model="name"></vs-input>
            <vs-input size="large" label="* 身分證字號" class="w-auto text-primary" v-model="id"></vs-input>
            <vs-input size="large" label="健保卡號" class="w-auto text-primary" v-model="hid"></vs-input>
            <vs-input size="large" label="出生日期" class="w-auto text-primary" v-model="dob"></vs-input>
        </div>
        <div class="grid grid-cols-1 gap-4">
            <vs-button class="rounded whitespace-no-wrap" @click="joinEvent">報名</vs-button>
        </div>
    </div>
</template>
<script>
import { scanCard, joinHpaEvent } from '@/api/user'
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
        active: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            photo: null, // 用於保存拍照後的圖像
            stream: null, // 保存攝像頭流的引用
            name: '',
            id: '',
            hid: '',
            dob: '',
            eventPatient: null,
        }
    },
    watch: {
        active: {
            handler(v) {
                if (v) this.startCamera()
                if (!v) this.stopCamera()
            },
            immediate: true,
        },
    },
    methods: {
        // 啟動攝像頭
        startCamera() {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: 'environment', // 設置為後置攝像頭
                    },
                })
                .then((stream) => {
                    this.stream = stream // 保存攝像頭流的引用
                    this.$refs.video.srcObject = stream
                })
                .catch((err) => {
                    console.error('後置攝像頭不可用，嘗試啟動前置攝像頭: ', err)
                    // 嘗試啟動前置攝像頭
                    navigator.mediaDevices
                        .getUserMedia({
                            video: true, // 默認使用前置攝像頭
                        })
                        .then((stream) => {
                            this.stream = stream // 保存攝像頭流的引用
                            this.$refs.video.srcObject = stream
                        })
                        .catch((err) => {
                            console.error('無法啟動攝像頭: ', err)
                        })
                })
        },
        // 關閉攝像頭
        stopCamera() {
            if (this.stream) {
                // 停止所有媒體流
                this.stream.getTracks().forEach((track) => track.stop())
                this.$refs.video.srcObject = null
                this.stream = null
            }
        },
        // 拍照
        takePhoto() {
            const video = this.$refs.video
            const canvas = this.$refs.canvas
            const context = canvas.getContext('2d')

            // 設置畫布尺寸與視頻相同
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight

            // 將視頻的當前幀繪製到畫布
            context.drawImage(video, 0, 0, canvas.width, canvas.height)

            // 將畫布轉換為圖像數據
            this.photo = canvas.toDataURL('image/png')
            this.photo = this.photo.replace(/^data:image\/[a-zA-Z]+;base64,/, '')

            this.scanCard()
        },
        // 掃描健保卡或身分證
        scanCard() {
            const payload = { photo: this.photo }
            scanCard(payload).then((res) => {
                console.log(res.data.data)
                const scan = res.data.data
                this.name = scan.name ? scan.name : ''
                this.id = scan.id ? scan.id : ''
                this.hid = scan.hid ? scan.hid : ''
                this.dob = scan.dob ? scan.dob : ''
            })
        },
        // 報名整篩活動
        joinEvent() {
            const payload = {
                hpa_event_id: this.data.id,
                account: this.id,
                name: this.name,
                dob: this.dob ? this.$common.formatDate(this.dob) : '',
                insurance_id: this.hid,
            }
            joinHpaEvent(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.stopCamera()
                    this.$bus.$emit('joinEvent', res.data.data)
                } else {
                    let payload = []
                    payload.content = '<div style="white-space: normal;">' + this.$t('membersMgmt.addmember.error.' + res.data.code) + '</div>'
                    payload.title = this.$t('membersMgmt.addmember.err_dialog_title')
                    this.$bus.$emit('errDialog', payload)
                }
            })
        },
    },
}
</script>
