import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
    uid: 0, // From Auth
    displayName: 'John Doe', // From Auth
    about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    // photoURL    : require("@/assets/images/portrait/small/avatar-s-11.png"), // From Auth
    photoURL: process.env.VUE_APP_UPLOAD + '/public/empty_personal.png', // From Auth
    status: 'online',
    userRole: 'admin',
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser: userDefaults,
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    mainLayoutType: themeConfig.mainLayoutType || 'vertical',
    navbarSearchAndPinList: navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    theme: themeConfig.theme || 'light',
    themePrimaryColor: colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    icd10all: [],
    memberTotal: 0,
    memberTotal2: 0,
    unreadmsg: 0,
    name: '',
    mid: 0,
    avatar: process.env.VUE_APP_UPLOAD + '/doctor/photo/noimage.png',
    bankImage: '',
    licenseImage: '',
    token: '',
    device: 3,
    // devicetoken: '',
    doctortype: 0,
    doctorStatus: 3000,
    personsign: [],
    videopath: '',
    showRightPanel: false,
    connectingqid: 0,
    queue: [],
    queuetotal: 0,
    queuenotfinishtotal: 0,
    profile: { account: '', name: '' },
    division: [],
    divisionStrC: [],
    divisionStrE: [],
    experience: {},
    allvipmembers: [],
    allvipmembers2: [],
    vipMemberSearchQuery: '',
    alldoctors: [],
    contacts: [],
    contactsall: [],
    roomList: [],
    roomNotify: false,
    callinfo: [],
    callinfofilter: 'week',
    callinfofilterbydate: { startdate: '', enddate: '' },
    chatContacts: [],
    chatContactsDoctor: [],
    chatContactswebsocket: [],
    chatContactsGroup: [],
    chatSearchQuery: '',
    chatDataOfUser: [],
    chatDataOfDoctor: [],
    chatDataOfGroup: [],
    activeChatUser: null,
    partnerEnterprise: [],
    userEMRBaseData: {},
    userEMRPersonSickData: {},
    // userEMRPersonSickDataOther: '',
    userEMRPersonotherdiesase: '',
    userEMRPersonSickFullData: [],
    userEMRParentsSickFullData: [],
    userEMRParentsCancerFullData: [],
    userEMRSensitiveMattersFullData: [],
    userEMRLifeTypeFullData: [],
    emrPersonSickData: '',
    userUltraSound: {},
    ectrocarDiogram: {},
    myService: [],
    myServiceLang: [],
    myMedicalAllNurse: [], //所有護理師資料
    chosenMedicalNurse: [], //以選取的護理師資料
    chosenMedicaldoctor: [], //以選取的醫師資料
    callInfoSearchQuery: '',
    ringStatus: 'stop',
    personalphoto: {},
    personaleye: {},
    personaldicom: {},
    personalsti: {},
    doctorSelectUnit: {},
    playCallInSound: false,
    playCallInSoundStatus: false,
    callInMask: false,
    callerName: '',
    callerPartnerEnterprise: { partner_name: '', enterprise_name: '' },
    wordS: {},
    wordO: {},
    wordA: {},
    wordP: {},
    wordMemo: {},
    wordNote: {},
    notes: {},
    servicelist: {},
    wordWD: {},
    wordDiet: {},
    planslist: {},
    partner: 0,
    partnerDoctor: 0,
    personBaseSign: {},
    personSign2: {},
    personSign3: {},
    personSign4: {},
    personSign5: {},
    personSign6: {},
    personSign7: {},
    personSign8: {},
    personSign9: {},
    personSign10: {},
    personSign11: {},
    personSign12: {},
    personSign13: {},
    personSign14: {},
    syncTriggle: '',
    queryPartnerEnterprise: '',
    referHospital: null,
    allHospital: [],
    roomid: 0,
    mrid: 0,
    qid: 0,
    verify: 0,
    serviceUnit: { name: '' },
    chatSelectedTab: 0,
    uiastaff: false,
    queuePmemo: '',
    allemployees: null,
    filterCallInfoDate: { start: null, end: null },
    todolist: [],
    mytodolist: [],
    tometodolistTotal: 0,
    mytodolistTotal: 0,
    tometodolistPage: 1,
    mytodolistPage: 1,
    myopinion: [],
    opinion: [],
    myopinionTotal: 0,
    opinionTotal: 0,
    myOpinionPage: 0,
    OpinionPage: 0,
    log: [],
    logtotal: 0,
    logsearch: false,
    logsearchpayload: null,
    logpage: 1,
    myopinion_start_date_time: null,
    myopinion_end_date_time: null,
    opinion_start_date_time: null,
    opinion_end_date_time: null,
    opFilterFlag: false,
    opinionAllData: null,
    opinionState: false,
    opinionId: 0,
    opinionType: null,
    opinionStatus: null,
    currentPid: '0',
    syncStatus: false,
    selectedSyncTab: '',
    syncSwitch: false,
    pauseSmallBtn: false,
    smsTime: [],
    isConnected: false,
    socketID: 0,
    selectedicd10: null,
    calling: false,
    selectedPatient: null,
    todayqueue: [],
    agentReservation: [],
    otherDayReservation: [],
    emergencyReservation: [],
    specialReservation: [],
    opinionActiveCount: 0,
    opinionActiveCountInTotal: 0,
    doctorEnable: 2,
    memoS: '',
    memoO: '',
    memoP: '',
    memoDiet: '',
    selectMemberDetail: null,
    activePatientInfo: null,
    did: 0,
    todoutotal: 0,
    mytodoutotal: 0,
    notifytotal: 0,
    scheduletotal: 0,
    msgtotal: 0,
    unFinishOpinion: 0,
    unFinishOpinionInTotal: 0,
    contactPatient: '民眾',
    contactDoctor: '醫事人員',
    contactGroup: '群組',
    chatPatientCount: 0,
    chatDoctorCount: 0,
    chatGroupCount: 0,
    socketChatMsgAndTime: [],
    reloadPageBtnShow: false,
    bioDataCodenamearray: null,
    bioData: null,
    totalGroup: 0,
    servicenotes: [],
    crmmembers: [],
    crmcases: [],
    filterCrmmembers: [],
    crmMemberSearchQuery: '',
    resverationAmNum: '',
    resverationPmNum: '',
    resverationNightNum: '',
    resverationOtherTimeNum: '',
    resverationAgentNum: '',
    resverationGoHosTimeNum: '',
    agentReservationCount: 0,
    otherDayReservationCount: 0,
    emergencyReservationCount: 0,
    specialReservationCount: 0,
    isDev: true,
    title: null,
    smsRoom: 0,
    totalCountExceptMember: 0,
    blocks: [],
    chatFirstClick: false,
    callinfototal: 0,
    reservationViewModeType: false,
    memberViewModeType: true,
    reservationfilterindex: null,
    emrTabSetting: [],
    emrMedication: [],
    reservationTabSetting: [],
    homeSetting: [],
    // inRoom: '',
    // mcudata: [],
    showSmallVideo: false,
    lifeHealthData: [],
    medicalTitleList: [],
    apptUndoneTotal: 0,
    agentApptUndoneTotal: 0,
    dietChartData: [],
    crmOpenTotal: 0,
    crmCloseTotal: 0,
    socketReceiveMsg: null,
    now: new Date(),
    crmNewCase: false,
    videoActive: false,
    callId: '',
    ring: '',
    localId: '',
    remoteId: '',
    uuid: '',
    checkInQueue: [],
    proxy: [],
    undoneApptAm: 0,
    undoneApptPm: 0,
    undoneApptNight: 0,
    undoneApptEmergency: 0,
    undoneApptSpec: 0,
    undoneApptCs: 0,
    undoneApptTotal: 0,
    undoneAgentApptAm: 0,
    undoneAgentApptPm: 0,
    undoneAgentApptNight: 0,
    undoneAgentApptEmergency: 0,
    undoneAgentApptSpec: 0,
    undoneAgentApptCs: 0,
    undoneAgentApptTotal: 0,
    callData: null,
    p2pRegister: false,
    allow_shop: false,
    is_p2p: false,
    partner_id: 0,
    partner_name: '',
    partner_list: [],
    unit_list: [],
    ui_config: null,
    jwt: '',
    pm_id: 0,
    comm_uid: '',
}

export default state
