<template>
    <div class="relative">
        <div class="vx-navbar-wrapper" :class="classObj" :style="emrFlag ? 'width:100%' : ''">
            <vs-navbar class="vx-navbar navbar-custom navbar-skelton" style="line-height: 1.2" :color="navbarColorLocal">
                <!-- SM - OPEN SIDEBAR BUTTON -->
                <vs-navbar-item>
                    <feather-icon class="sm:inline-flex cursor-pointer mr-1" :class="emrFlag ? '' : 'xl:hidden'" icon="MenuIcon" @click.stop="showSidebar"></feather-icon>
                </vs-navbar-item>
                <!-- UIA 客戶服務系統 Title -->
                <vs-navbar-item v-if="windowWidth >= 992">
                    <div v-show="partner_id == 31">UIA 客戶服務系統</div>
                </vs-navbar-item>
                <vs-spacer />
                <!-- 系統更新 重新整理按鈕 -->
                <vs-navbar-item class="mr-3">
                    <vs-button :size="windowWidth < 700 ? 'small' : 'default'" v-show="reloadPageBtnShow" color="danger" @click="reloadPageFunc">{{ $t('navbar.reload') }}</vs-button>
                </vs-navbar-item>
                <!-- 系統更新提示視窗 -->
                <el-dialog :title="$t('popup.alert')" :visible.sync="reloaddialogmessage" :width="windowWidth < 768 ? '90%' : '40%'" center append-to-body :show-close="false" :before-close="handleconfirmreloadclose">
                    <h4>{{ $t('navbar.newVersionReload') }}</h4>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="reloaddialogmessage = false">{{ $t('navbar.later') }}</el-button>
                        <el-button type="primary" @click="reloadPageFunc">{{ $t('navbar.reload') }}</el-button>
                    </span>
                </el-dialog>
                <!-- 合作醫事人員通話 -->
                <vs-navbar-item class="cd-navbar-group">
                    <vx-tooltip :text="'合作醫事人員通話'" color="primary" position="bottom">
                        <feather-icon icon="UsersIcon" class="cursor-pointer hover:text-primary" @click="openCallPopup"></feather-icon>
                    </vx-tooltip>
                </vs-navbar-item>
                <!-- 掛號管理 -->
                <vs-navbar-item class="cd-navbar-group" v-if="doctortype != 5">
                    <vx-tooltip :text="$t('navbar.reservation')" color="primary" position="bottom">
                        <feather-icon icon="CalendarIcon" class="cursor-pointer hover:text-primary" :badge="undoneApptTotal > 0 ? undoneApptTotal : null" @click="gotoReservation"></feather-icon>
                    </vx-tooltip>
                </vs-navbar-item>
                <!-- 聊天室 -->
                <vs-navbar-item class="cd-navbar-group" v-if="doctortype != 5">
                    <vx-tooltip :text="$t('navbar.chat')" color="primary" position="bottom">
                        <feather-icon icon="MessageCircleIcon" class="cursor-pointer hover:text-primary bgred" :badge="msgtotal" @click="gotoMessage"></feather-icon>
                    </vx-tooltip>
                </vs-navbar-item>
                <!-- 邀請房間 -->
                <vs-navbar-item class="cd-navbar-group blink" v-if="roomList.length > 0">
                    <!-- <vx-tooltip :text="'邀請房間'" color="primary" position="bottom"> -->
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                        <feather-icon id="roomBtn" icon="PhoneIcon" class="cursor-pointer hover:text-primary" :badge="roomList.length" @click="toggleRoom"></feather-icon>
                        <vs-dropdown-menu class="vx-navbar-dropdown">
                            <RoomList @close="toggleRoom"></RoomList>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    <!-- </vx-tooltip> -->
                </vs-navbar-item>
                <!-- 醫事狀態 -->
                <vs-navbar-item class="cd-navbar-group">
                    <!-- <vx-tooltip :text="getDoctorStatusText(doctorStatus)" color="primary" position="bottom"> -->
                    <div class="flex items-center cursor-pointer space-x-1 whitespace-no-wrap" @click="updateDoctorStatus">
                        <feather-icon :icon="statusIcon" :svgClasses="statusColor"></feather-icon>
                        <span>{{ getDoctorStatusText(doctorStatus) }}</span>
                    </div>
                    <!-- </vx-tooltip> -->
                </vs-navbar-item>
                <!-- USER META -->
                <vs-navbar-item class="cd-navbar-group">
                    <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
                        <!-- 姓名 & 狀態 | 職位 -->
                        <!-- <div class="text-right leading-tight sm:block mr-2 whitespace-no-wrap" style="min-width: 5rem" v-if="windowWidth > 768">
                            <p>{{ user_displayName }}</p>
                            <small v-if="title">{{ getDoctorStatusText(doctorStatus) }} | {{ $i18n.locale == 'en' ? title.ename : title.cname }}</small>
                        </div> -->
                        <!-- 頭像 -->
                        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                            <div class="con-img">
                                <!-- <span :class="$socket.connected ? 'notify-badge' : ''"></span> -->
                                <img v-if="activeUserImg" key="onlineImg" :src="activeUserImg" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
                            </div>
                            <vs-dropdown-menu class="vx-navbar-dropdown">
                                <ul style="min-width: 10rem">
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click.prevent="getProfile">
                                        <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2" @click.prevent="getProfile">{{ name }}{{ $common.getLangDB(title) }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="$router.push('/message/notify').catch(() => {})">
                                        <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">{{ $t('navbar.notify') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="openPartnerPopup = true" v-if="partner_list.length > 1">
                                        <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">{{ $t('navbar.changePartner') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="popupUpdate = true">
                                        <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">{{ $t('navbar.updateInfo') }}</span>
                                    </li>
                                    <vs-divider class="my-1"></vs-divider>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="updateLocale('tw')">
                                        <feather-icon icon="GlobeIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">繁體中文</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="updateLocale('cn')">
                                        <feather-icon icon="GlobeIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">简体中文</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="updateLocale('en')">
                                        <feather-icon icon="GlobeIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">English</span>
                                    </li>
                                    <vs-divider class="my-1"></vs-divider>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-grey-light" @click="logout">
                                        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">{{ $t('navbar.logout') }}</span>
                                    </li>
                                </ul>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                </vs-navbar-item>
            </vs-navbar>
        </div>
        <vs-popup :title="$t('navbar.updateInfo')" :active.sync="popupUpdate">
            <span>2023/01/17</span><br />
            <span>* 掛號管理發佈新版本；只需於掛號管理頁點選[體驗新版]。</span><br />
            <span>* 操作介面優化、強化未完診之排序與呈現。</span><br />
            <span>* 操作介面優化、快速篩選檢視所需之掛號。</span><br />
            <span>* 大幅提升網頁速度。</span><br />
            <span>* 新增[代理掛號管理]功能，提升護理行政人員工作效率</span><br />
            <span>* 使用者可隨時切換為舊版、並歡迎提供建議。</span><br />
            <br />
            <span>2022/10/20</span><br />
            <span>新增[掛號紀錄]主選單，說明如下：</span><br />
            <span>1. 優化掛號紀錄的查詢，提供更友善更多元的掛號紀錄查詢功能。</span><br />
            <span>2. 原有[諮詢紀錄]同時整入[掛號紀錄]。</span><br />
            <br />
            <span>2022/07/01</span><br />
            <span>[掛號管理] 新增"視訊邀請"功能，說明如下：</span><br />
            <span>1. 醫事人員可點選"視訊邀請"，並以簡訊通知民眾進入視訊診間。</span><br />
            <span>2. 民眾點選簡訊連結進入視訊診間後，醫師端會收到提醒訊息並出現"看診"按鍵，點擊"看診"即可進入民眾等待之視訊診間。</span><br />
            <span>3. "通話"功能維持不變，醫師可保有原操作習慣進行看診。</span><br />
            <br />
            <span>2022/4/15</span><br />
            <span>1. [醫療檔案上傳]上傳檔案大小上限提升。</span><br />
            <span>2. [醫療檔案上傳]呈現文案優化。</span><br />
            <span>3. [掛號管理]如果有簡訊通話的掛號，進入掛號管理頁彈跳通知問題修復。</span><br />
            <span>4. [授權管理]醫師授權護理師班表管理，呈現規則優化。</span><br />
            <br />
        </vs-popup>
        <!-- 選擇合作夥伴視窗 -->
        <select-partner :openPopup="openPartnerPopup" @closePartnerPopup="closePartnerPopup"></select-partner>
    </div>
</template>

<script>
import { updateBadge, getRoomList } from '@/api/user'
import selectPartner from '@/components/selectPartner.vue'
import RoomList from '@/components/Room/RoomList'
import { mapState } from 'vuex'
export default {
    name: 'the-navbar',
    inject: ['reload'],
    props: {
        navbarColor: {
            type: String,
            default: '#fff',
        },
    },
    components: {
        selectPartner,
        RoomList,
    },
    data() {
        return {
            maxHeight: '0px',
            openItems: false,
            statusIcon: 'PauseIcon', //'PlayIcon'//'SlashIcon' //'UserXIcon' //'UserIcon'
            statusColor: 'fill-current text-danger',
            reloaddialogmessage: false,
            popupUpdate: false,
            openPartnerPopup: false,
            emrFlag: false,
            roomActive: false,
        }
    },
    watch: {
        $route() {
            if (this.verticalNavMenuItemsMin) return
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            this.emrFlag = route == 'emr2' || route == 'appthistory' || route == 'apptmgmt' || route == 'monitor' || route == 'membersmgmt' || route == 'freeclinic' || route == 'tb'
            let scrollHeight = this.scrollHeight

            // Collapse Group
            if (this.openItems && !this.open) {
                this.maxHeight = `${scrollHeight}px`
                setTimeout(() => {
                    this.maxHeight = `${0}px`
                }, 50)

                // Expand Group
            } else if (this.open) {
                this.maxHeight = `${scrollHeight}px`
                setTimeout(() => {
                    this.maxHeight = 'none'
                }, 300)
            }
        },
        maxHeight() {
            this.openItems = this.maxHeight != '0px'
        },
        roomList(v) {
            if (v.length > 0 && !this.roomActive) {
                const button = document.getElementById('roomBtn')
                button.click()
            }
        },
    },
    mounted() {
        const route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
        this.emrFlag = route == 'emr2' || route == 'appthistory' || route == 'apptmgmt' || route == 'monitor' || route == 'membersmgmt' || route == 'freeclinic' || route == 'tb'
        this.checkRoom()
        this.$store.dispatch('fetchDoctorStatus')
    },
    computed: {
        ...mapState(['undoneApptTotal', 'windowWidth', 'partner_id', 'partner_list', 'name', 'title', 'doctortype', 'roomList', 'roomNotify', 'jwt']),
        totalCountExceptMember() {
            return this.$store.getters.totalCountExceptMember
        },
        //判斷重新整理
        reloadPageBtnShow() {
            if (this.$store.getters.reloadPageBtnShow == true) {
                this.reloaddialogmessage = true
                return this.$store.getters.reloadPageBtnShow
            }
            return this.$store.getters.reloadPageBtnShow
        },
        msgtotal() {
            return this.$store.getters.msgtotal
        },
        device() {
            return this.$store.getters.device
        },
        verticalNavMenuItemsMin() {
            return this.$store.state.verticalNavMenuItemsMin
        },
        doctorStatus() {
            if (this.$store.state.doctorStatus === 1000) {
                this.statusIcon = 'PlayIcon'
                this.statusColor = 'fill-current text-primary'
            } else if (this.$store.state.doctorStatus === 2000) {
                this.statusIcon = 'PhoneCallIcon'
                this.statusColor = 'fill-current text-warning'
            } else if (this.$store.state.doctorStatus === 3000) {
                this.statusIcon = 'PauseIcon'
                this.statusColor = 'fill-current text-danger'
            }
            return this.$store.state.doctorStatus
        },
        // I18N
        getCurrentLocaleData() {
            const locale = this.$i18n.locale
            // console.log('locale', locale)
            if (locale == 'tw') return { flag: 'tw', lang: '繁體中文' }
            else if (locale == 'cn') return { flag: 'cn', lang: '简体中文' }
            else if (locale == 'en') return { flag: 'us', lang: 'English' }
            return ''
        },
        queuenotfinishtotal() {
            return this.$store.getters.queuenotfinishtotal == 0 ? '' : this.$store.getters.queuenotfinishtotal
        },
        navbarColorLocal() {
            return this.$store.state.theme === 'dark' ? '#10163a' : this.navbarColor
        },
        // HELPER
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        // NAVBAR STYLE
        classObj() {
            if (this.verticalNavMenuWidth == 'default') {
                return 'navbar-default'
            } else if (this.verticalNavMenuWidth == 'reduced') {
                return 'navbar-reduced'
            } else if (this.verticalNavMenuWidth) {
                return 'navbar-full'
            }
        },
        // PROFILE
        activeUserInfo() {
            return this.$store.state.AppActiveUser
        },
        activeUserImg() {
            return this.$store.getters.avatar
        },
        myProfile: {
            get() {
                return this.$store.getters.profile
            },
        },
        socketReceiveMsg() {
            return this.$store.state.socketReceiveMsg
        },
    },
    methods: {
        // 確認是否關閉重新整理
        handleconfirmreloadclose() {
            this.$confirm(this.$t('navbar.closeReload')).then((_) => {
                this.reloaddialogmessage = false
            })
        },
        // 重新整理
        reloadPageFunc() {
            this.$store.commit('SET_RELOAD_PAGE', false)
            window.location.reload(true)
        },
        // 取醫師狀態 語系
        getDoctorStatusText(status) {
            var statusMap = {
                1000: this.$t('navbar.onlineStatus'),
                2000: this.$t('navbar.busyStatus'),
                3000: this.$t('navbar.offlineStatus'),
            }
            return statusMap[status]
        },
        // 切換語系
        updateLocale(locale) {
            this.reload()
            if (locale == 'en') {
                localStorage.setItem('language', 'en')
            } else if (locale == 'tw') {
                localStorage.setItem('language', 'zh_TW')
            } else if (locale == 'cn') {
                localStorage.setItem('language', 'zh_CN')
            }
            this.$i18n.locale = locale
        },
        // 切換醫師狀態
        updateDoctorStatus() {
            var tmpstatus = 3000
            if (this.doctorStatus === 1000) {
                tmpstatus = 2000
                this.statusIcon = 'PhoneCallIcon'
                this.statusColor = 'fill-current text-danger'
            } else if (this.doctorStatus === 2000) {
                tmpstatus = 3000
                this.statusIcon = 'PauseIcon'
                this.statusColor = 'fill-current text-success'
            } else if (this.doctorStatus === 3000) {
                tmpstatus = 1000
                this.statusIcon = 'PlayIcon'
                this.statusColor = 'fill-current text-success'
            }
            this.$store.dispatch('updateDoctorStatus', tmpstatus)
        },
        // 導向到掛號管理
        gotoReservation() {
            this.$router.push('/apptmgmt/1').catch(() => {})
        },
        // 導向到個人資訊
        getProfile() {
            this.$router.push('/setting/profile').catch(() => {})
        },
        // 開啟主選單sidebar
        showSidebar() {
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
        },
        // 導向到聊天室
        gotoMessage() {
            var _self = this
            _self.$router.push('/message/chat').catch(() => {})
        },
        // 登出
        logout() {
            console.log('登出 - 正常登出')
            this.$store.dispatch('updateDoctorStatus', 3000)
            if (this.device == 1) {
                updateBadge(this.totalCountExceptMember)
            }
            this.$store.dispatch('logout').then(() => {
                window.location.reload()
            })
        },
        // 關閉 選擇合作夥伴視窗
        closePartnerPopup(v) {
            this.openPartnerPopup = false
        },
        // 監聽跟我關的視訊房間
        checkRoom() {
            const _self = this
            this.setInterval = setInterval(function () {
                if (_self.jwt) _self.$store.dispatch('getRoomList')
            }, 5000)
        },
        toggleRoom() {
            this.roomActive = !this.roomActive
            this.$store.commit('SET_ROOM_NOTIFY', this.roomActiveue)
        },
        // 開啟選擇通話對象視窗
        openCallPopup() {
            const payload = {
                title: '合作醫事人員通話',
            }
            this.$bus.$emit('callList', payload)
        },
    },
    beforeDestroy() {
        clearInterval(this.setInterval)
    },
}
</script>
<style lang="css">
.notify-badge {
    position: absolute;
    background: #2d9b32;
    height: 1rem;
    top: 1.5rem;
    right: -0.5rem;
    width: 1rem;
    text-align: center;
    line-height: 2rem;
    font-size: 0.5rem;
    border-radius: 50%;
    color: white;
    border: 1px solid #2d9b32;
}
.doctorstatusselect > div > input.el-input__inner {
    font-size: 1rem;
    color: #000 !important;
}
.el-select .el-input .el-select__caret {
    color: #000 !important;
}
.el-input.is-disabled .el-input__inner {
    color: #000 !important;
}
</style>
