<template>
    <div>
        <div class="p-4 flex justify-between items-center flex-wrap bg-white whitespace-no-wrap rounded">
            <div class="flex items-center flex-wrap space-x-4">
                <div class="">
                    <span class="text-2xl" v-if="event">{{ event.name }}</span>
                </div>
                <vs-dropdown vs-trigger-click class="text-primary cursor-pointer hover:font-semibold" v-if="events.length > 1">
                    <span>切換活動</span>
                    <vs-dropdown-menu class="w-64 whitespace-no-wrap overflow-hidden">
                        <vs-dropdown-item v-for="event in events" :key="event.id" @click="changeEvent(event)">{{ event.name }}</vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <span v-if="!event">{{ $t('freeclinic.freeclinicEventList') }}</span>
            </div>
            <div class="my-2 flex items-center flex-wrap space-x-4" v-if="event">
                <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-32 rounded" v-model="query" />
                <div>
                    <span class="text-primary cursor-pointer hover:font-semibold" @click="refresh">刷新</span>
                </div>
                <vs-button class="cd-button-2w rounded" @click="openForm">＋ 報名</vs-button>
            </div>
        </div>
        <div class="py-2" v-if="event">
            <ScreeningTable :event_id="event.id" :data="eventPatients"></ScreeningTable>
        </div>
        <vs-popup :active.sync="activeForm" title="整篩活動報名" @close="closeForm">
            <OverLimitWarning v-if="overLimitActive" :overLimitActive="overLimitActive"></OverLimitWarning>
            <SuccessForm v-else-if="joinSuccess" :joinSuccess="joinSuccess"></SuccessForm>
            <JoinForm :data="event" :active="activeForm" v-else-if="event"></JoinForm>
        </vs-popup>
    </div>
</template>
<script>
import { getHpaEvents, getHpaEventPatients } from '@/api/user'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import ScreeningTable from '@/components/Screening/ScreeningTable'
import JoinForm from '@/components/Screening/JoinForm.vue'
import SuccessForm from '@/components/Screening/SuccessForm.vue'
import OverLimitWarning from '@/components/Screening/OverLimitWarning.vue'
export default {
    components: { ScreeningTable, JoinForm, SuccessForm, OverLimitWarning },
    data() {
        return {
            events: [],
            event: null,
            eventPatients: [],
            query: '',
            activeForm: false,
            joinSuccess: false,
            overLimitActive: false,
        }
    },
    watch: {
        query() {
            this.getHpaEventPatients()
        },
    },
    computed: {
        ...mapState(['partner_id']),
    },
    async mounted() {
        this.$vs.loading()
        try {
            await this.getHpaEvents()
            await this.getHpaEventPatients()
        } finally {
            this.createBus()
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
            this.$vs.loading.close()
        }
    },
    methods: {
        // 取整篩活動
        async getHpaEvents() {
            try {
                const payload = { partner_id: this.partner_id }
                const res = await getHpaEvents(payload)
                this.events = res.data.data.event
                this.event = this.events.length > 0 ? this.events[0] : null
            } catch (error) {
                console.error('取整篩活動失敗', error)
            }
        },
        // 取整篩活動報名民眾
        getHpaEventPatients: debounce(async function () {
            try {
                const payload = { hpa_event_id: this.event.id, query: this.query }
                const res = await getHpaEventPatients(payload)
                this.eventPatients = res.data.data
            } catch (error) {
                console.error('取整篩活動報名民眾失敗', error)
            }
        }, 200),
        // 切換活動
        changeEvent(event) {
            this.event = event
            this.getHpaEventPatients()
        },
        //刷新
        async refresh() {
            this.$vs.loading()
            try {
                await this.getHpaEventPatients()
            } finally {
                this.$vs.loading.close()
            }
        },
        openForm() {
            const limit = this.event.limit
            if (limit !== null && limit <= this.eventPatients.length) this.overLimitActive = true
            this.activeForm = true
        },
        closeForm() {
            this.activeForm = false
            this.overLimitActive = false
            this.joinSuccess = false
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('joinEvent', (data) => {
                this.refresh()
                this.joinSuccess = true
            })
            this.$bus.$on('cancelHpaEventRegistration', (data) => {
                this.refresh()
            })
            this.$bus.$on('close', () => {
                this.closeForm()
            })
            this.$bus.$on('overLimitAccept', () => {
                this.overLimitActive = false
            })
            this.$bus.$on('overLimitCancel', () => {
                this.closeForm()
            })
        },
    },
}
</script>
