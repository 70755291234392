<template>
  <div>
    <vs-button
      class="cd-button-4w"
      :type="type"
      :color="color"
      :disabled="disabled"
      :icon="icon"
      icon-pack="feather"
      @click.stop="showChatPopup"
      :style="windowWidth<450 &&$route.path=='/members' ?  'font-size:12px':''">
      {{$t('components.msg.msg')}}
    </vs-button>
    <!-- 訊息視窗 -->
    <vs-popup :title="$t('components.msg.msg')" :active.sync="chatPopup">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-end">
          <importSetWord :dataType="'wd/all'" @importMsg="importMsg($event)" @closeChatPopup="closeChatPopup"></importSetWord>
        </vs-col>
        <vs-col>
          <vs-textarea height="200" :placeholder="$t('components.msg.content')" v-model="message"/>
        </vs-col>
        <vs-col>
          <vs-button class="cd-button-2w" @click.native="sendChat">{{$t('components.msg.send')}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import importSetWord from '@/components/importSetWord'
import { saveChatData } from '@/api/user'

export default {
  components: {
    importSetWord
  },
  props: {
    type: {
      type: String,
      default: "border",
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    icon: {
      type: String,
      default: "icon-message-circle",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    selectedMid: {
      required: true
    },
  },
  computed: {
    ...mapState(['partner_id']),
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },
  data() {
    return {
      chatPopup: false,
      message: '',
    }
  },
  methods: {
    // 開啟傳送訊息視窗
    showChatPopup() {
      this.chatPopup = true
    },
    // 傳送訊息
    sendChat() {
      let _self = this
      if(_self.message=='' || _self.selectedMid==''){
        _self.notify('danger', _self.$t('message.send_error'), _self.$t('message.checkField'))
        return false
      }
      var payload = {
        'id': _self.selectedMid,
        'to_mid_type': 0,
        'msg_group_id': 0,
        'msg': {
          "textContent": _self.message,
          "time": String(new Date()),
        },
        'type': "member",
        'partner_id': this.partner_id
      }
      saveChatData(payload).then(res => {
        if(res.data.status == 'OK'){
          _self.$vs.notify({
            color: 'grey',
            text: _self.$t('message.send_success'),
            position:'top-center'
          })
          _self.message = ''
          _self.chatPopup = false
        }
      })
    },
    // 匯入常用字句
    importMsg(msg){
      // 判斷最後一個字元是不是換行符號
      let lastWord = this.message.split("")[this.message.split("").length-1]
      if(lastWord != '\n' && lastWord != undefined) {
        this.message = this.message + '\n'
      }
      this.message += msg
    },
    // 關閉傳送訊息視窗
    closeChatPopup(){
      this.chatPopup = false
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>