<template>
    <div v-if="report_list[0]">
        <vs-row>
            <vs-col v-for="(item, index) in report_list" :key="index" vs-lg="4" vs-sm="6" vs-xs="12">
                <div class="cd-card-group text-center">
                    <vs-card>
                        <vs-row class="cd-card">
                            <vs-col class="cd-text-group" vs-type="flex" vs-justify="center">
                                <p>{{ item.time }} {{ $t('emr.healthreport.checkup_title') }}</p>
                            </vs-col>
                            <vs-divider />
                            <vs-col vs-type="flex" vs-justify="space-around">
                                <vs-button size="small" class="px-8" @click="openReport(item)">{{ $t('OpenBtn') }}</vs-button>
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </div>
            </vs-col>
        </vs-row>
        <vs-popup :title="$t('emr.healthreport.checkup_title')" :active.sync="report_popup" @close="report_popup = false">
            <vs-card v-if="report_data[0]">
                <div style="background-color: #fff; text-align: center; padding: 1em; font-size: 1rem">
                    <vs-row>健檢日期：{{ report_data[0].time }}</vs-row>
                    <vs-row style="margin: 1rem">
                        <vs-col v-for="(value, index) in report_data" :key="index" vs-type="flex" vs-justify="space-between" style="font-weight: bold; padding: 0 1rem">
                            <span>
                                {{ value.health_sub.sub_c }}
                            </span>
                            <span>
                                {{ value.value }}
                            </span>
                        </vs-col>
                    </vs-row>
                </div>
            </vs-card>
        </vs-popup>
    </div>
    <div class="cd-nodata" v-else>
        {{ $t('nodata') }}
    </div>
</template>

<script>
import { fetchPersonCheckUpList, fetchcheckupreport } from '@/api/user'
export default {
    components: {},
    props: {
        userPid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            report_list: [],
            report_data: [],
            report_popup: false,
        }
    },
    created() {
        this.getCheckUpList()
    },
    watch: {},
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods: {
        // 判斷語系
        geti18n(items) {
            if (this.$i18n.locale == 'tw') {
                return items.tw
            } else if (this.$i18n.locale == 'cn') {
                return items.cn
            } else if (this.$i18n.locale == 'en') {
                return items.en
            }
        },
        getCheckUpList() {
            const payload = { spid: this.userPid }
            fetchPersonCheckUpList(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (res.data.data) {
                        this.report_list = res.data.data
                    }
                }
            })
        },
        openReport(report) {
            console.log(report)
            console.log(report.time)
            const payload = { time: report.time, pid: this.userPid }
            fetchcheckupreport(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (res.data.data) {
                        this.report_data = res.data.data
                    }
                }
            })
            this.report_popup = true
        },
    },
    destroyed() {
        //   this.$store.commit('SET_SYNC_SWITCH', false)
    },
}
</script>
<style>
.red {
    color: red;
}
.orange {
    color: rgb(253, 173, 23);
}
.green {
    color: green;
}
</style>
