<template>
    <page class="cd-el-select">
        <vs-row class="p-4" style="background: rgb(255, 255, 255)">
            <vs-col vs-w="6" vs-xs="12">
                <span style="font-size: 26px">遠距之愛偏鄉義診 {{ eventlist.name }}</span>
            </vs-col>
            <vs-col class="sm:justify-end justify-start" vs-w="6" vs-xs="12" vs-type="flex">
                <vs-select v-model="event.eventid" placeholder="請選擇義診活動" name="item-category">
                    <vs-select-item :key="item.id" :value="item.id" :text="item.name" v-for="item in eventlist" />
                </vs-select>
                <vs-button class="ml-4 cd-button-2w" @click="refresh">刷新</vs-button>
            </vs-col>
        </vs-row>
        <vs-row class="p-4">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <el-tabs class="w-full" v-model="event.itemid">
                    <el-tab-pane label="總表">
                        <el-table class="cursor-pointer" :height="windowHeight" :data="item_memberlist" style="width: 100%" @cell-click="select_popup">
                            <el-table-column class="cursor-pointer" prop="name" label="姓名" width="110" />
                            <el-table-column prop="account" label="身分證" width="140" />
                            <el-table-column prop="2" id="2" label="血壓" width="70" v-if="type[2]" />
                            <el-table-column prop="3" id="3" label="血糖" width="70" v-if="type[3]" />
                            <el-table-column prop="13" id="13" label="膽固醇" width="70" v-if="type[13]" />
                            <el-table-column prop="14" id="14" label="尿酸" width="70" v-if="type[14]" />
                            <!-- <el-table-column prop="4" id="4" label="血氧" width="70"  />  v-if="type[4]" -->
                            <el-table-column prop="6" id="6" label="體重" width="70" v-if="type[6]" />
                            <el-table-column prop="7" id="7" label="心電圖" width="80" v-if="type[7]" />
                            <!-- <el-table-column prop="8" id="8" label="呼吸器" width="70"  />  v-if="type_7" -->
                            <!-- <el-table-column prop="9" id="9" label="身高機" width="70"  />  v-if="type_7" -->
                            <el-table-column prop="10" id="10" label="眼底鏡" width="130" v-if="type[10]" />
                            <el-table-column prop="11" id="11" label="聽力" width="80" v-if="type[11]" />
                            <el-table-column prop="12" id="12" label="超音波" width="150" v-if="type[12]" />
                        </el-table>
                    </el-tab-pane>
                    <!-- <el-tab-pane :name="items.id" v-for="(items, index) in itemlist" :key="index" >
            <span style="font-size: 16px;" slot="label">
              {{items.name}}
            </span>
            
              <el-table :data="item_memberlist"  style="width:55%" height="300px">
                <el-table-column prop="name" label="姓名" width="80" />
                <el-table-column prop="account" label="身分證ID"  />
                <el-table-column prop="mtype_id" label="項目"  />
                <el-table-column prop="value" label="量測結果"  />
              </el-table>
          </el-tab-pane> -->
                </el-tabs>
            </vs-col>
        </vs-row>
        <!-- <div>
      <vs-card class="p-4 w-66">
        <el-table :data="list"  style="width:55%" height="300px">
          <el-table-column prop="name" label="姓名" width="80" />
          <el-table-column prop="account" label="身分證ID"  />
          <el-table-column prop="value" label="量測結果"  />
        </el-table>
      </vs-card>
    </div> -->

        <!-- 輸入量測數據視窗 -->
        <vs-popup class="px-2" :active.sync="popupupcreatedata" :title="'輸入' + this.event.name + '量測數據'">
            <VuePerfectScrollbar :style="'height: ' + (windowHeight - 200) + 'px;'" :settings="settings">
                <!-- 血壓 -->
                <vs-row class="mb-base" v-if="select_type[2] || select_type[0]">
                    <vs-col class="px-2 cd-form-group">
                        <h5>血壓</h5>
                    </vs-col>
                    <vs-col class="px-2 cd-form-group" vs-w="6" vs-xs="12">
                        <span>收縮壓 ex.120</span>
                        <vs-input type="number" v-model="input['2_1']" :placeholder="temp2_1" />
                    </vs-col>
                    <vs-col class="px-2 cd-form-group" vs-w="6" vs-xs="12">
                        <span>舒張壓 ex.80</span>
                        <vs-input type="number" v-model="input['2_2']" :placeholder="temp2_2" />
                    </vs-col>
                </vs-row>
                <vs-row class="mb-base">
                    <!-- 血糖 -->
                    <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[3] || select_type[0]">
                        <h5>血糖</h5>
                        <vs-input type="number" v-model="input['3']" :placeholder="temp3" />
                    </vs-col>
                    <!-- 膽固醇 -->
                    <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[13] || select_type[0]">
                        <h5>膽固醇</h5>
                        <vs-input type="number" v-model="input['13']" :placeholder="temp13" />
                    </vs-col>
                    <!-- 尿酸 -->
                    <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[14] || select_type[0]">
                        <h5>尿酸</h5>
                        <vs-input type="number" v-model="input['14']" :placeholder="temp14" />
                    </vs-col>
                    <!-- 血氧 -->
                    <!-- <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[4]||select_type[0]">
            <h5>血氧</h5>
            <vs-input type="number" v-model="input['4']"  :placeholder="temp4"/>
          </vs-col> -->
                    <!-- 體溫 -->
                    <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[5] || select_type[0]">
                        <h5>體溫</h5>
                        <vs-input type="number" v-model="input['5']" :placeholder="temp5" />
                    </vs-col>
                    <!-- 體重 -->
                    <vs-col vs-w="6" vs-xs="12" class="px-2 cd-form-group" v-if="select_type[6] || select_type[0]">
                        <h5>體重</h5>
                        <vs-input type="number" v-model="input['6']" :placeholder="temp6" />
                    </vs-col>
                </vs-row>
                <!-- 心電圖 -->
                <vs-row class="mb-base" v-if="select_type[7] || select_type[0]">
                    <vs-col class="px-2 cd-form-group">
                        <h5>心電圖</h5>
                    </vs-col>
                    <vs-col class="cd-form-group px-4 grid">
                        <vs-radio class="my-1" v-model="input['7']" vs-name="ecg" vs-value="0" @change="change_mtype_select">無異常</vs-radio>
                        <vs-radio class="my-1" v-model="input['7']" vs-name="ecg" vs-value="1" @change="change_mtype_select">心律不整</vs-radio>
                        <vs-radio class="my-1" v-model="input['7']" vs-name="ecg" vs-value="2" @change="change_mtype_select">心肌缺氧</vs-radio>
                        <vs-radio class="my-1" v-model="input['7']" vs-name="ecg" vs-value="3" @change="change_mtype_select">異常</vs-radio>
                        <vs-radio class="my-1" v-model="input['7']" vs-name="ecg" vs-value="4" @change="change_mtype_select">其他</vs-radio>
                    </vs-col>
                </vs-row>
                <!-- 眼底鏡 -->
                <vs-row class="mb-base" v-if="select_type[10] || select_type[0]">
                    <vs-col class="px-2 cd-form-group">
                        <h5>眼底鏡</h5>
                    </vs-col>
                    <vs-col class="cd-form-group px-4 flex items-center">
                        <span class="mr-8 cd-p-large">左眼</span>
                        <vs-radio class="my-1 mx-2" v-model="input['10_1']" vs-name="fds" vs-value="0" @change="change_mtype_select">無異常</vs-radio>
                        <vs-radio class="my-1 mx-2" v-model="input['10_1']" vs-name="fds" vs-value="1" @change="change_mtype_select">異常</vs-radio>
                    </vs-col>
                    <vs-col class="cd-form-group px-4 flex items-center">
                        <span class="mr-8 cd-p-large">右眼</span>
                        <vs-radio class="my-1 mx-2" v-model="input['10_2']" vs-name="fdd" vs-value="0" @change="change_mtype_select">無異常</vs-radio>
                        <vs-radio class="my-1 mx-2" v-model="input['10_2']" vs-name="fdd" vs-value="1" @change="change_mtype_select">異常</vs-radio>
                    </vs-col>
                </vs-row>
                <!-- 聽力 -->
                <vs-row class="mb-base" v-if="select_type[11] || select_type[0]">
                    <vs-col class="px-2 cd-form-group">
                        <h5>聽力</h5>
                    </vs-col>
                    <vs-col class="cd-form-group px-4 flex items-center">
                        <span class="mr-8 cd-p-large">左耳</span>
                        <vs-radio class="my-1 mx-2" v-model="input['11_1']" vs-name="earl" vs-value="0" @change="change_mtype_select">無異常</vs-radio>
                        <vs-radio class="my-1 mx-2" v-model="input['11_1']" vs-name="earl" vs-value="1" @change="change_mtype_select">異常</vs-radio>
                    </vs-col>
                    <vs-col class="cd-form-group px-4 flex items-center">
                        <span class="mr-8 cd-p-large">右耳</span>
                        <vs-radio class="my-1 mx-2" v-model="input['11_2']" vs-name="earr" vs-value="0" @change="change_mtype_select">無異常</vs-radio>
                        <vs-radio class="my-1 mx-2" v-model="input['11_2']" vs-name="earr" vs-value="1" @change="change_mtype_select">異常</vs-radio>
                    </vs-col>
                </vs-row>
                <!-- 超音波 -->
                <vs-row class="mb-base" v-if="select_type[12] || select_type[0]">
                    <vs-col class="px-2 cd-form-group">
                        <h5>超音波</h5>
                    </vs-col>
                    <vs-col class="ml-4">
                        <span class="cd-p-large">腎臟</span>
                    </vs-col>
                    <vs-col class="cd-form-group grid ml-4">
                        <vs-checkbox class="my-1" v-model="input12_1" vs-name="kid" vs-value="0" @change="change_mtype_select">無異常</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_1" vs-name="kid" vs-value="1" @change="change_mtype_select">結石</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_1" vs-name="kid" vs-value="2" @change="change_mtype_select">水腎</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_1" vs-name="kid" vs-value="3" @change="change_mtype_select">腎水泡</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_1" vs-name="kid" vs-value="4" @change="change_mtype_select">其他</vs-checkbox>
                    </vs-col>
                    <vs-col class="ml-4">
                        <span class="cd-p-large">肝臟</span>
                    </vs-col>
                    <vs-col class="cd-form-group grid ml-4">
                        <vs-checkbox class="my-1" v-model="input12_2" vs-name="liver" vs-value="0" @change="change_mtype_select">無異常</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_2" vs-name="liver" vs-value="1" @change="change_mtype_select">肝硬化</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_2" vs-name="liver" vs-value="2" @change="change_mtype_select">肝水泡</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_2" vs-name="liver" vs-value="3" @change="change_mtype_select">肝腫瘤</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_2" vs-name="liver" vs-value="4" @change="change_mtype_select">其他</vs-checkbox>
                    </vs-col>
                    <vs-col class="ml-4">
                        <span class="cd-p-large">腹主動脈</span>
                    </vs-col>
                    <vs-col class="cd-form-group grid ml-4">
                        <vs-checkbox class="my-1" v-model="input12_3" vs-name="AA" vs-value="0" @change="change_mtype_select">無異常</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_3" vs-name="AA" vs-value="1" @change="change_mtype_select">鈣化</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_3" vs-name="AA" vs-value="2" @change="change_mtype_select">腹主動脈瘤</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_3" vs-name="AA" vs-value="3" @change="change_mtype_select">其他</vs-checkbox>
                    </vs-col>
                    <vs-col class="ml-4">
                        <span class="cd-p-large">膽囊</span>
                    </vs-col>
                    <vs-col class="cd-form-group grid ml-4">
                        <vs-checkbox class="my-1" v-model="input12_4" vs-name="GB" vs-value="0" @change="change_mtype_select">無異常</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_4" vs-name="GB" vs-value="1" @change="change_mtype_select">膽結石</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_4" vs-name="GB" vs-value="2" @change="change_mtype_select">膽息肉</vs-checkbox>
                        <vs-checkbox class="my-1" v-model="input12_4" vs-name="GB" vs-value="3" @change="change_mtype_select">其他</vs-checkbox>
                    </vs-col>
                </vs-row>
            </VuePerfectScrollbar>
            <!-- 填寫人 -->
            <vs-row class="p-4" style="background-color: #eeeeee">
                <vs-col>
                    <div class="flex justify-center items-center">
                        <span class="mr-2">填寫人</span>
                        <div class="flex items-center">
                            <vs-select class="w-24" v-model="inputer">
                                <vs-select-item :key="item" :value="item" :text="item" v-for="item in inputer_list" />
                            </vs-select>
                            <vs-button class="ml-4 cd-button-2w" @click="updatemeasuredata">送出</vs-button>
                        </div>
                    </div>
                </vs-col>
            </vs-row>
        </vs-popup>
    </page>
</template>

<script>
import { pid } from 'process'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
    inject: ['reload'],
    data() {
        return {
            event: {
                eventid: 0,
                itemid: 0,
                pid: 0,
                name: '',
            },
            itemlist: [],
            checklist: [],
            item_memberlist: [],
            memberlist: [
                { name: '測試一', account: 'A123456789' },
                { name: '測試二', account: 'B123456789' },
                { name: '測試三', account: 'C123456789' },
                { name: '測試四', account: 'D123456789' },
                { name: '測試五', account: 'E123456789' },
                { name: '測試六', account: 'F123456789' },
                { name: '測試七', account: 'G123456789' },
            ],
            inputer_list: ['林健盛', '林明潔', '姜彥蓉', '吳佳璐', '鍾金凌', '鄭名道', '陳亞茵', '李金賢', '林素華', '游若文', '楊忠謀'],
            activeName: '總表',
            temp2_1: '',
            temp2_2: '',
            temp3: '',
            temp4: '',
            temp5: '',
            temp6: '',
            temp7: '',
            temp8: '',
            temp9: '',
            temp10_1: '',
            temp10_2: '',
            temp11_1: '',
            temp11_2: '',
            temp12_1: '',
            temp12_2: '',
            temp12_3: '',
            temp12_4: '',
            temp13: '',
            temp14: '',
            input: [],
            input12_1: [],
            input12_2: [],
            input12_3: [],
            input12_4: [],
            input13: '',
            input14: '',
            eventlist: [],
            stationConfig: [],
            maintable: [],
            type: [],
            select_type: [],
            inputer: '',
            tw_lang: ['aa', '123'],
            popupupcreatedata: false,
            popupuptype2: false,
            popupuptype3: false,
            popupuptype4: false,
            popupuptype5: false,
            popupuptype6: false,
            popupuptype2: false,
            popupuptype2: false,
            popupuptype2: false,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.7,
            },
        }
    },
    watch: {
        'event.eventid': function () {
            this.fetchMonitorEventItemList()
        },
        'event.itemid': function () {
            this.fetchMonitorMemberList()
        },
    },
    components: {
        vSelect,
        VuePerfectScrollbar,
    },
    computed: {
        windowHeight() {
            return window.innerHeight - 200
        },
    },
    async mounted() {
        // await this.fetchconfig();
        await this.fetcheventlist()
        await this.fetchMonitorMemberList()
        // this.reload();
        // setInterval(() => {
        //   _self.medicineFeeback = null
        //   _self.suggestionFeeback = null
        // }, 60000)
        // this.decimaltoarray(18)
    },
    methods: {
        async updatemeasuredata() {
            // this.$vs.loading()
            let data = {
                input2_1: this.input['2_1'],
                input2_2: this.input['2_2'],
                input3: this.input['3'],
                input4: this.input['4'],
                input5: this.input['5'],
                input6: this.input['6'],
                input7: this.input['7'],
                input10_1: this.input['10_1'],
                input10_2: this.input['10_2'],
                input11_1: this.input['11_1'],
                input11_2: this.input['11_2'],
                input12_1: this.arraytodecimal(this.input12_1),
                input12_2: this.arraytodecimal(this.input12_2),
                input12_3: this.arraytodecimal(this.input12_3),
                input12_4: this.arraytodecimal(this.input12_4),
                input13: this.input['13'],
                input14: this.input['14'],
                eventid: this.event.eventid,
                pid: this.event.pid,
                writer: this.inputer,
            }

            console.log(data)
            await axios.post(process.env.VUE_APP_API_URL_HIS + '/updateMonitorData', { data: data }).then((res) => {
                // axios.post('http://localhost.his.com/updateMonitorData',{'data':data,}).then((res) => {
                if (res.data.status == 'OK') {
                    this.$vs.notify({ color: 'grey', title: '新增完成', text: this.event.name + '資料新增完成', position: 'top-center' })

                    console.log('updateMonitorData')
                    this.popupupcreatedata = false
                    this.resetdata()
                    this.fetchMonitorEventItemList()
                    // window.location.reload()
                    // console.log('123',this.event)
                } else {
                    this.$vs.notify({ color: 'danger', title: '新增失敗', text: res.data.message, position: 'top-center' })
                    console.log('updateMonitorData error')
                }
            })
            this.$vs.loading.close()
        },
        select_popup(row, column, cell, event) {
            console.log('select_popup', column.property)
            //點帳號姓名可輸入全部
            this.select_type = []
            if (column.property != 'account' && column.property != 'name') {
                this.select_type[column.property] = true
            } else {
                this.select_type[0] = true
            }
            this.popupupcreatedata = true
            this.fetchMonitorDataByPid(row.pid)
            this.event.pid = row.pid
            this.event.name = row.name
        },
        change_mtype_select(selectitem) {
            console.log('bbb', selectitem, this.select_type)
            console.log(this.input12_1)
        },
        fetchconfig() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchFreeClinicConfig/measuredata/item').then((res) => {
                // axios.get('http://localhost.his.com/fetchFreeClinicConfig/measuredata/item').then((res) => {
                if (res.data.status == 'OK') {
                    this.stationConfig = res.data.data
                    console.log('stationConfig', this.stationConfig)
                } else {
                    console.log('get config error')
                }
            })
        },
        fetchMonitorMemberList() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorMemberList/' + this.event.eventid + '/' + this.event.itemid).then((res) => {
                // axios.get('http://localhost.his.com/fetchMonitorMemberList/'+this.event.eventid+'/'+this.event.itemid).then((res) => {
                if (res.data.status == 'OK') {
                    this.item_memberlist = res.data.list
                    // this.itemlist.push({'id':0,'name':'總表'})
                    console.log('item_memberlist', this.item_memberlist)
                } else {
                    console.log('get EventItem error')
                }
            })
        },
        //義診項目列表
        fetchMonitorEventItemList() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorEventItemList/' + this.event.eventid + '/1').then((res) => {
                // axios.get('http://localhost.his.com/fetchMonitorEventItemList/'+this.event.eventid+'/1').then((res) => {
                if (res.data.status == 'OK') {
                    this.itemlist = res.data.eventlist
                    // this.itemlist.push({'id':0,'name':'總表'})
                    console.log('ItemList', this.itemlist)
                    this.itemlist.forEach((e) => {
                        this.type[e.id] = true
                    })
                } else {
                    console.log('get EventItem error')
                }
                console.log('type', this.type)
            })
            this.fetchMonitorMemberList()
        },
        //民眾已填寫資料
        async fetchMonitorDataByPid(spid) {
            await axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorDataByPid/' + spid).then((res) => {
                // axios.get('http://localhost.his.com/fetchMonitorEventItemList/'+this.event.eventid+'/1').then((res) => {
                if (res.data.status == 'OK') {
                    console.log('ItemList', res.data.rs)
                    this.temp2_1 = res.data.rs[0].data[0].value
                    this.temp2_2 = res.data.rs[0].data[1].value
                    this.temp3 = res.data.rs[1].data[0].value
                    // this.temp4 = res.data.rs[].data[0].value
                    this.temp5 = res.data.rs[2].data[0].value
                    this.temp6 = res.data.rs[3].data[0].value
                    this.temp7 = res.data.rs[4].data[0].value
                    this.temp8 = res.data.rs[5].data[0].value
                    this.temp9 = res.data.rs[6].data[0].value
                    this.temp10_1 = res.data.rs[7].data[0].value
                    this.temp10_2 = res.data.rs[7].data[0].value
                    this.temp11_1 = res.data.rs[8].data[0].value
                    this.temp11_2 = res.data.rs[8].data[1].value
                    this.temp12_1 = this.decimaltoarray(res.data.rs[9].data[0].value)
                    this.temp12_2 = this.decimaltoarray(res.data.rs[9].data[1].value)
                    this.temp12_3 = this.decimaltoarray(res.data.rs[9].data[2].value)
                    this.temp12_4 = this.decimaltoarray(res.data.rs[9].data[3].value)
                    this.temp13 = res.data.rs[10].data[0].value
                    this.temp14 = res.data.rs[11].data[0].value
                    // this.itemlist = res.data
                    // this.itemlist.push({'id':0,'name':'總表'})
                    // console.log('temp12_1',this.temp12_1,res.data.rs[9].data[0].value,this.decimaltoarray(res.data.rs[9].data[0].value))
                } else {
                    console.log('get EventItem error')
                }
            })
        },
        //義診活動列表
        fetcheventlist() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorEventList').then((res) => {
                const _self = this
                if (res.data.status == 'OK') {
                    this.eventlist = res.data.eventlist
                    console.log('eventlist', this.eventlist)
                }
            })
        },
        resetdata() {
            ;(this.input2_1 = ''),
                (this.input2_2 = ''),
                (this.input3 = ''),
                (this.input4 = ''),
                (this.input5 = ''),
                (this.input6 = ''),
                (this.input7 = ''),
                (this.input10_1 = ''),
                (this.input10_2 = ''),
                (this.input11_1 = ''),
                (this.input11_2 = ''),
                (this.input12_1 = []),
                (this.input12_2 = []),
                (this.input12_3 = []),
                (this.input12_4 = []),
                (this.input13 = ''),
                (this.input14 = '')
        },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                time: 8000,
                position: 'top-center',
            })
        },
        //刷新
        refresh() {
            this.resetdata()
            this.fetchMonitorEventItemList()
        },
        //二進制
        arraytodecimal(array) {
            console.log(array)
            var temp_count = 0
            array.forEach((e) => {
                if (e == 0) {
                    temp_count += 1
                } else if (e == 1) {
                    temp_count += 2
                } else if (e == 2) {
                    temp_count += 4
                } else if (e == 3) {
                    temp_count += 8
                } else if (e == 4) {
                    temp_count += 16
                } else if (e == 5) {
                    temp_count += 32
                }
            })
            return temp_count > 0 ? temp_count : null
        },
        decimaltoarray(index) {
            let array = new Array()
            while (index > 0) {
                array.push(index % 2)
                index = Math.floor(index / 2)
            }
            console.log('decimaltoarray', array)
            return array
        },
    },
}
</script>
