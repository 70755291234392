// import { getToken } from '@/utils/auth'
// import { API_URL } from "../../vue.config";
import axios from 'axios'
//process.env
var config = {
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
    withCredentials: false,
}
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'https://api-beta.curdoctor.com.tw/his' : process.env.VUE_APP_API_URL_HIS
//axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://his.curdr.com' : process.env.VUE_APP_API_URL_HIS
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt') // 'https://api-beta.curdoctor.com.tw/v2'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // 'https://api-beta.curdoctor.com.tw/v2'
// axios.defaults.withCredentials = true;
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')
myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('jwt'))

function getToken() {
    return localStorage.getItem('jwt')
}
export function login(data) {
    return axios.post(process.env.VUE_APP_API_URL + '/app/restful_api/user/login', data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: '',
        },
        withCredentials: false,
    })
}
export function sendWeChat(data) {
    return axios.post('/api/sendwechat', data, config)
}
export function sendCLC(data) {
    return axios.post('/api/sendroombythirdpart', data, config)
}
export function updateBadge(data) {
    return axios.get('/api/updateBadge/' + data, config)
}
export function sendRoomBySms(data) {
    return axios.post('/api/sendroombysms', data, config)
}
export function sendRoomByLINE(data) {
    return axios.post('/api/sendlinemsg', data, config)
}
export function changePassword(data) {
    return axios.post('/api/changepw', data, config)
}
export function fetchEmployees(data) {
    return axios.post('/api/employees', data, config)
}
export function saveTodoList(data) {
    return axios.post('/api/todolist', data, config)
}
export function updateTodoList(data) {
    return axios.put('/api/todolist', data, config)
}
export function updateTodoListStatus(data) {
    return axios.put('/api/todoliststatus', data, config)
}
export function callout(data) {
    return axios.post('/api/callout', data, config)
}
export function callout2(data) {
    return axios.post('/api/callout2', data, config)
}
// export function getRoomMembers(data) {
//     var config = { headers: { 'Content-Type': 'application/json' } }
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
//     return axios.get('/api/roommembers/' + data.roomid, config)
// }
export function getRoom() {
    return axios.get('/api/meetingroom', config)
}
export function fetchSchedule(page) {
    return axios.get('/api/schedule/' + page, config)
}
// 預約通知
export function saveSchedule(data) {
    return axios.post('/api/scheduletype', data, config)
}
export function updateSchedule(data) {
    return axios.post('/api/schedule', data, config)
}
export function delSchedule(data) {
    return axios.post('/api/delschedule', data, config)
}
export function fetchToMeTodoList(page) {
    return axios.get('/api/tometodolist/' + page, config)
}
export function fetchMyTodoList(page) {
    return axios.get('/api/mytodolist/' + page, config)
}
export function fetchAllBankList() {
    return axios.get('/api/bank', config)
}
export function fetchMyOpinion(page) {
    return axios.get('/api/opinions/1/' + page, config)
}
export function fetchMyLog(data) {
    return axios.post('/api/gethisdoctorlog', data, config)
}
export function fetchOpinion(page) {
    return axios.get('/api/opinions/2/' + page, config)
}
export function insertMyBank(data) {
    return axios.post('/api/bankdata', data, config)
}
export function addRoom(data) {
    return axios.post('/api/meetingroom', data, config)
}
export function delRoom(data) {
    return axios.post('/api/delroom', data, config)
}
export function fetchHospital() {
    return axios.get('/api/allhospital', config)
}
export function addRefHospital(data) {
    return axios.post('/api/referhospital', data, config)
}
export function updateDoctorUnit(data) {
    return axios.post('/api/updatedoctorunit', data, config)
}
// 病歷 取醫療影像
export function fetchEmrImage(data) {
    return axios.get('/api/imagebyhis/' + data.spid + '/' + data.type + '/' + data.page, config)
}
// 目前沒用
export function saveSuggest(data) {
    return axios.post('/api/savesuggest', data, config)
}
export function saveRefHosp(data) {
    return axios.post('/api/refhosp', data, config)
}
export function updateServiceFee(data) {
    return axios.post('/api/servicefee', data, config)
}
export function removeContact(data) {
    return axios.post('/api/removecontact', data, config)
}
export function saveContact(data) {
    return axios.post('/api/contacts', data, config)
}
export function fetchContact() {
    return axios.get('/api/contacts', config)
}
export function fetchMessage(page) {
    return axios.get('/api/messages/' + page, config)
}
export function fetchAllDoctor() {
    return axios.get('/api/alldoctor', config)
}
export function getMRIDbyqid(data) {
    return axios.post('/api/mridbyqidanddid', data, config)
}
export function getMRList(data) {
    return axios.post('/api/personalmr', data, config)
}
export function getMRCustomerList(data) {
    return axios.post('/api/customermr', data, config)
}
export function GetmedicationRecord(data) {
    return axios.post('/external/GetMedicationRecord', data, config)
}
export function getMRIdByQId(data) {
    const uri = process.env.VUE_APP_API_URL_HIS + '/api/mridbyqid/' + data
    return fetch(uri, {
        method: 'GET',
        headers: myHeaders,
    })
        .then((res) => {
            return res.json()
        })
        .then((result) => {
            return result
        })
        .catch((err) => {
            console.log('MRID ERROR:', err)
        })
}
// 更新建議紀錄
export function updateMRNoteData(data) {
    return axios.post('/api/mrnotedata', data, config)
}
export function getMRData(data) {
    return axios.get('/api/mrdata/' + data.qid + '/' + data.mrid, config)
}
export function fetchQueueDetail(data) {
    return axios.post('/api/queuepmemo', data, config)
}
export function updateHistoryPerson(data) {
    return axios.post('/api/historyperson', data, config)
}
export function getHistoryPersonSick(params) {
    return axios.get('/api/historyperson/' + params.spid + '/' + params.type, config)
}
export function getHistoryPersonSickOther(params) {
    return axios.get('/api/getpatienthistoryother/' + params.spid + '/' + params.type, config)
}
export function fetchPartnerEnterprise(data) {
    return axios.post('/api/partnerenterprise', data, config)
}
export function getHistory(data) {
    return axios.get('/api/history/' + data.spid, config)
}
export function updateHistory(data) {
    return axios.post('/api/history', data, config)
}
export function updateHistoryother(data) {
    return axios.post('/api/updatepatienthistoryother', data, config)
}
export function searchDoctor(data) {
    return axios.post('/api/querydoctor', data, config)
}
export function getUnread() {
    return axios.get('/api/unread/')
}
export function fetchPersonBaseSign0(data) {
    return axios.get('/api/personsignbyt/' + data.spid)
}
export function fetchPersonBaseSign(data) {
    return axios.get('/api/personalreport/' + data.spid)
}
export function fetchPersonSign2(data, group) {
    return axios.get('/api/personalsign2/' + data + '/' + group, config)
}
export function fetchPersonSign(data) {
    return axios.get('/api/personsign/' + data, config)
}
export function fetchPersonCheckUpList(data) {
    return axios.get('/api/fetchcheckuplist/' + data.spid)
}
export function fetchcheckupreport(data) {
    return axios.post('/api/fetchcheckupreport', data)
}
export function fetchRefsHospital() {
    return axios.get('/api/referhospital', config)
}
export function fetchNotes(data) {
    return axios.get('/api/notes/' + data.pid + '/' + data.page, config)
}
export function addNote(data) {
    return axios.post('/api/note', data, config)
}
export function updateNote(data) {
    return axios.post('/api/updatenote', data, config)
}
export function delNote(data) {
    return axios.post('/api/delnote', data, config)
}
export function fetchWords(data) {
    console.log(data)
    return axios.get('/api/words/' + data, config)
}
export function fetchService(data) {
    return axios.get('/api/getdocservice/' + data, config)
}
export function fetchPlans(data) {
    return axios.get('/api/getdocplans/' + data, config)
}
export function addWord(data) {
    return axios.post('/api/words', data, config)
}
export function editWord(data) {
    return axios.put('/api/words', data, config)
}
export function delWord(data) {
    return axios.post('/api/delword', data, config)
}
export function fetchEMRBaseData(data) {
    return axios.get('/api/emrbasedata/' + data.userPid + '/' + data.partnerid, config)
}
export function fetchCallInfo(data) {
    return axios.post('/api/callinfo', data, config)
}
// export function fetchCallInfoSearch(data) {
//   return axios.post('/api/callinfosearch', data, config)
// }
export function fetchICD10(data) {
    if (data == null) {
        return axios.get('/api/soap_icd10', config)
    } else {
        return axios.get('/api/soap_icd10?query=' + data, config)
    }
}
// export function fetchMedicine(data) {
//   if(data==null){
//     return axios.get('/api/words_medical', config)
//   }
//   else{
//     return axios.get('/api/words_medical?medical_all_field='+data, config)
//   }
// }
export function fetchMedicineFrequence(data) {
    if (data == null) {
        return axios.get('/api/words_frequence', config)
    } else {
        return axios.get('/api//words_frequence?frequence_id_name=' + data, config)
    }
}
export function fetchAgentReservation(data) {
    return axios.get('/api/agentreservation?partnerid=' + data.partnerid + '&sdid=' + data.sdid, config)
}
export function fetchOtherDayReservation(data) {
    return axios.get('/api/otherdayreservation?partnerid=' + data.partnerid + '&sdid=' + data.sdid, config)
}
export function fetchemergencyReservation(data) {
    return axios.get('/api/uaireservation?partnerid=' + data.partnerid + '&sdid=' + data.sdid, config)
}
export function fetchSpecialReservation(data) {
    return axios.get('/api/specreservation?partnerid=' + data.partnerid + '&sdid=' + data.sdid, config)
}
export function fetchQueue(data) {
    return axios.post('/api/queue', data, config)
}
export function finishANDSWStatus(data) {
    return axios.post('/api/finishandswstatus', data, config)
}
export function saveQueueANDMR(data) {
    return axios.post('/api/savequeueandmr', data, config)
}
export function callPatient(data) {
    return axios.post('/api/call', data, config)
}
export function reCallPatient(data) {
    return axios.post('/api/recall', data, config)
}
export function diverDoctor(data) {
    return axios.post('/api/diverdoctor', data, config)
}
export function updateQueueFinish(data) {
    return axios.post('/api/updatequeue', data, config)
}
export function getDoctorStatus() {
    return axios.get('/api/doctorstatus', config)
}
export function updateDoctorStatus(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/doctorstatus', data, config)
}
//更新call_info狀態
export function updatecallinfostatus(data) {
    return axios.post('/api/updatecallinfostatus', data, config)
}
export function getMember(data) {
    return axios.post('/api/member', data, config)
}
export function getVIPMembers(payload) {
    var queryString = ''
    if (payload)
        queryString =
            '?' +
            Object.keys(payload)
                .map((key) => key + '=' + payload[key])
                .join('&')
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getPartnerMemberList' + queryString + '&limit=10', config)
}
export function getProfile() {
    return axios.get('/api/profile', config)
}
export function getallDivisions() {
    return axios.get('/api/alldivision', config)
}
export function getDivisions() {
    return axios.get('/api/adivision', config)
}
export function updateDivision(data) {
    return axios.post('/api/division', data, config)
}
export function updateMyService(data) {
    return axios.post('/api/myservice', data, config)
}
export function updateMyServiceLang(data) {
    return axios.post('/api/myservicelang', data, config)
}
export function fetchServiceLang() {
    return axios.get('/api/myservicelang', config)
}
export function updateExperience(data) {
    return axios.post('/api/experience', data, config)
}
export function updatePersonalData(data) {
    return axios.post('/api/profile', data, config)
}
export function getServiceList(data) {
    return axios.get('/api/servicelist/' + data.caseid + '/' + data.pid, config)
}
export function getServiceTitle(data) {
    return axios.get('/api/servicetitle/' + data.partnerid + '/' + data.pid, config)
}
export function saveService(data) {
    return axios.post('/api/servicenote', data, config)
}
export function saveCaseTitle(data) {
    return axios.post('/api/servicetitle', data, config)
}
export function saveCase(data) {
    return axios.post('/api/savecase', data, config)
}
export function getServiceDetail(data) {
    return axios.get('/api/servicedetail/' + data.caseid + '/' + data.spid, config)
}
export function updateCaseFinish(data) {
    return axios.put('/api/updatecase/' + data.caseid, config)
}
export function updatecaseStatus(data) {
    return axios.put('/api/updatecasestatus/' + data.caseid, data, config)
}
export function crmMembers(data) {
    return axios.get('/api/crmmembers/' + data.partnerid, config)
}
export function caseDetail(data) {
    return axios.get('/api/casedetail/' + data.pid, config)
}
export function itemDetail(data) {
    return axios.get('/api/itemdetail/' + data.caseid, config)
}
export function getInfo() {
    return axios.get('/api/userinfo/' + localStorage.getItem('device_type'))
}
export function reGetInfo() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/userinfo', config)
}
export function fetchPhotoPath(params) {
    return axios.post('/api/photopath', params, config)
}
export function logout(data) {
    return axios.post('/api/logout', { type: data }, config)
}
export function registerUser(data) {
    return axios.post('/api/register', data, config)
}
export function updatePatientBadge(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/updatepatientbadge', data)
}
export function getnotify() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/doctor/pushmessage')
}
export function getQuestionAndAnswer(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/getquestionlist/' + data.pid + '/' + data.page + '/' + data.limit) //2616 +data.pid
}
export function getQuestionList(data) {
    return axios.post('/api/getdoctorquestion', data, config)
}
export function fetchChatContacts(page) {
    if (page == undefined) {
        page = 1
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/chatcontact3/' + page, config)
}
export function fetchChatContactsDoctor() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/chatcontactdoctor', config)
}
export function fetchChatContactsGroup() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/chatgroup', config)
}
export function fetchChatData(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/chatmsgbymid', data, config)
}
export function fetchChatDataDoctor(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/chatdoctormsgbymid', data, config)
}
export function fetchExcelChatLog(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/excelchatlog', data, config)
}
export function saveNewDoctorChat(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.post('/api/newdoctorchat', data, config)
}
export function saveChatData(data) {
    return axios.post('/api/savechatmsg', data, config)
}
export function recycleChatData(data) {
    return axios.post('/api/recycleMessage', data, config)
}
export function messageAddGroup(data) {
    return axios.post('/api/chatmsggroup', data, config)
}
export function createdMsg(data) {
    return axios.post('/api/invituserchat', data, config)
}
export function getCountByWeek() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/weekqueuestatus', config)
}
export function getCountByMonth(data) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
    return axios.get('/api/monthqueuestatus/' + data.year + '/' + data.month, config)
}
export function getchat() {
    return axios.get('https://101.curdoctor.com.tw/api/message', config)
}
// export function fetchDoctorShift(data) {
//   return axios.post('/api/getdoctorshift', data, config)
// }
export function checkMemberFreePay(data) {
    return axios.post('/api/checkmemberfreepay', data, config)
}
export function searchpartnermember(data) {
    return axios.post('/api/searchpartnermember', data, config)
}
export function checkMember(data) {
    return axios.post('/api/checkmember', data, config)
}
// export function fetchOrder(data) {
//   return axios.post('/api/fetchorder', data, config)
// }
export function reservation(data) {
    return axios.post('/api/reservation', data, config)
}
export function filterreservation(data) {
    return axios.post('/api/searchpastrvation', data, config)
}
export function fetchOpinionCount() {
    return axios.get('/api/opinionactivecount', config)
}
//個人排班
// export function updateShift(data) {
//   return axios.put('/api/shift', data, config)
// }
//其他人排班
// export function updateShift2(data) {
//   return axios.put('/api/shift', data, config)
// }
// export function getShift(sdid) {
//   return axios.get('/api/shift/'+sdid, config)
// }
// export function getShiftOther(sdid) {
//   return axios.get('/api/shift/'+sdid, config)
// }
// 取休假資料
// export function getLeave(data) {
//   return axios.post('/api/shift_expt_show', data, config)
// }
// 新增休假
// export function insertLeave(data) {
//   return axios.post('/api/shift_expt', data, config)
// }
// 刪除休假
// export function deleteLeave(data) {
//   return axios.post('/api/shift_expt', data, config)
// }
// 檢查欲關閉班表是否有影響已掛號民眾 回傳人數total
export function noticeDoctorShift(data) {
    return axios.post('/api/noticedoctorshift', data, config)
}
export function updatelimit(data) {
    return axios.post('/api/updatelimit', data, config)
}
export function getCRMList(data) {
    return axios.get('/api/crmlist', data, config)
}
export function getUIAPartnrtList(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getUiaMonitorPartnerList?is_uia=' + data.is_uia, config)
}
export function getUIAMonitorPartnrtList(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getUiaMonitorPartnerList?is_uia=' + data.is_uia + '&is_health=' + data.is_health, config)
}
export function getEnterpriseList(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getPartnerMonitorAndEnterprise?partner_id=' + data.partnerid + '&is_uia=' + data.is_uia + '&is_health=' + data.is_health, config)
}
export function searchVIPMembers(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getPartnerMonitorMemberList?partner_id=' + data.partnerid + '&monitor=' + data.groupid + '&eid=' + data.enterpriseid + '&is_uia=' + data.is_uia + '&query=' + data.query + '&limit=' + data.limit + '&page=' + data.page, config)
}
export function searchMonitorMembers(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getPartnerMonitorMemberHealthData?partner_id=' + data.partnerid + '&tmp_file=' + data.tmp_file + '&limit=' + 10 + '&monitor=' + data.groupid + '&eid=' + data.enterpriseid + '&is_uia=' + data.is_uia + '&query=' + data.query, config)
}
export function searchMonitorMembersPages(data) {
    return axios.get(
        process.env.VUE_APP_API_URL +
            '/partnerapi/getPartnerMonitorMemberHealthDataPage?partner_id=' +
            data.partnerid +
            '&tmp_file=' +
            data.tmp_file +
            '&page=' +
            data.page +
            '&limit=' +
            10 +
            '&monitor=' +
            data.groupid +
            '&eid=' +
            data.enterpriseid +
            '&is_uia=' +
            data.is_uia +
            '&query=' +
            data.query,
        config
    )
}
export function partnerPatientRecordAuthorize(data) {
    return axios.get(process.env.VUE_APP_API_URL + '/partnerapi/getPartnerPatientRecordAuthorize?pid=' + data, config)
}
export function getCountByTodo() {
    return axios.get('/api/todoutotal', config)
}
export function editdoctorphone(data) {
    return axios.post('/api/editdoctorphone', data, config)
}
export function deletehospitalmap(data) {
    return axios.post('/api/deletehospitalmap', data, config)
}
export function RevokeLineaccesstoken() {
    return axios.post('/api/profile/revoke_line_notify_access_token', config)
}
export function BindLineaccesstoken(data) {
    return axios.post('/api/profile/line_notify_access_token', data, config)
}
export function updateMsgAllRead() {
    return axios.get('/api/updateallmsgread', config)
}
// 取聊天室未讀數
export function getCountByUnreadMsg() {
    return axios.get('/api/unreadmsgtotal', config)
}
// 取通知未讀數
export function getCountByUnreadNotify() {
    return axios.get('/api/unreadnotifytotal', config)
}
// 取我的預約未讀數
export function getCountByUnreadSchedule() {
    return axios.get('/api/unreadscheduletotal', config)
}
// 醫療檔案上傳搜尋符合的使用者
export function searchpatient(data) {
    return axios.post('/api/uploadsearch', data, config)
}
export function updateMyNurse(data) {
    return axios.post('/api/updatedoctornursebind', data, config)
}
// 取得所有護理師
export function getAllNurse() {
    return axios.get('/api/gethospitalallnurse', config)
}
// 取得所有綁定的醫師
export function getAllDoctorbind(hid) {
    return axios.get('/api/checknursedoctorlist?hid=' + hid, config)
}
// 更新班表授權名單
export function updateAllNursebind(data_object) {
    var data_object_array = { nurse_did: [] }
    if (data_object.length == 0) {
        data_object_array['nurse_did'].push({
            nurse_did: null,
        })
    } else {
        data_object.forEach((element) => {
            data_object_array['nurse_did'].push({
                nurse_did: element.nurse_did,
            })
        })
    }
    return axios.post('/api/updatedoctornursebind', data_object_array, config)
}
export function searchOpinion(data, type) {
    return axios.post('/api/searchopinion/' + type, data, config)
}
export function updateOpinionStatus(data) {
    return axios.put('/api/opinion', data, config)
}
export function SaveOpinion(data) {
    return axios.post('/api/opinion', data, config)
}
export function showuploaddoctor(typeid) {
    return axios.get('/api/showuploaddata/' + typeid, config)
}
export function getamorecgdata(typeid) {
    return axios.get('/api/getamorecgdata/' + typeid, config)
}
export function saveSignByType(data) {
    return axios.post('/api/savepersonalsign', data, config)
}
export function getGroupMembers(data) {
    return axios.post('/api/groupmembers', data, config)
}
export function getEnterpriseByChatAdmin(data) {
    return axios.post('/api/getenterprisebychatadmin', data, config)
}
export function getEnterpriseMembers(data) {
    return axios.post('/api/getenterprisemembers', data, config)
}
export function getClientListByMember(data) {
    return axios.post('/api/getclientbymember', data, config)
}
// 取振興生化檢驗
export function gethospitalbiodata(data) {
    return axios.get('/api/hospital_bio_data/' + data, config)
}
// 取屏基生化檢驗
export function getHospitalPtchBioData(data) {
    return axios.post('/external/GetPTCHLabData', data, config)
}
export function getRefreshToken(data) {
    return axios.post(process.env.VUE_APP_API_URL + '/app/restful_api/user/refresh', data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        withCredentials: false,
    })
}
export function getVideoNameByMr(data) {
    return axios.get('/api/videonamebymr/' + data.mrid, config)
}
// export function fetchDoctorOnDay(data) {
//   const myHeaders = new Headers();
//   myHeaders.append('Content-Type', 'application/json');
//   myHeaders.append('Authorization', 'Bearer '+ localStorage.getItem('di_tokenId'));
//   const uri = process.env.VUE_APP_API_URL_HIS+'/api/doctoronday/'+data.sdid;
//   return fetch(uri, {
//     method:'GET',
//     headers: myHeaders
//   })
//   .then(res => {
//       return res.json();
//   }).then(result => {
//       return result
//   }).catch((err) => {
//     console.log('取醫師班表錯誤:', err);
//   });
// }
// 用pid取得部分病人資訊
export function getPatientInfoByPid(data) {
    return axios.post('/api/getPatientInfoByPid', data, config)
}
// 取 變更密碼狀態
export function changePasswordStatus() {
    return axios.post('/api/changepwstatus', config)
}
// 登入時新增操作紀錄
export function loginLog() {
    return axios.post('/api/login_log', config)
}
// 用pid取mid
export function getMidByPid(pid) {
    return axios.get('/api/getmidbypid/' + pid, config)
}
// 用did取mid
export function getMidByDid(did) {
    return axios.get('/api/getmidbydid/' + did, config)
}
// 病歷-基本資料 修改會員 高級暢打狀態
export function hisUpdateMemberSeniorMember(data) {
    return axios.post(process.env.VUE_APP_API_URL + '/partnerapi/hisUpdateMemberSeniorMember', data, config)
}
// 病歷-基本資料 加入合作夥伴單位功能
export function hisPatientAddPartnerAndEnterprise(data) {
    return axios.post(process.env.VUE_APP_API_URL + '/partnerapi/hisPatientAddPartnerAndEnterprise', data, config)
}
// 病歷-基本資料 檢查是否'開啟企業連接功能'或'高級會員'的權限，並取單位清單
export function checkEmrBaseAuthThenGetList(data) {
    return axios.post('/api/checkEmrBaseAuthThenGetList', data, config)
}
// 取屏基 病歷-醫療影像-放射影像
export function fetchPtchXrayData(data) {
    return axios.post('/external/fetchPtchXrayData', data, config)
}
// 取屏基 病歷-醫療影像-放射影像
export function fetchPtchExamData(data) {
    return axios.post('/external/fetchPtchExamData', data, config)
}
// 更新 完診狀態->保留看診
export function updateFinishStatus(data) {
    return axios.post('/api/updateFinishStatus', data, config)
}
// 取醫師建議紀錄的歷史更新紀錄
export function fetchSuggestHistory(data) {
    return axios.get('/api/fetchSuggestHistory/' + data.mrid + '/' + data.qid, config)
}
// 掛號管理 取授權醫師名單(護士或客服用)
export function fetchAuthDoctorList() {
    return axios.get('/api/fetchAuthDoctorList', config)
}
// 授權管理 取醫生綁定的同合作夥伴護士
export function getAllNursebind(partnerid) {
    return axios.get('/api/checkdoctornursebind/' + partnerid, config)
}
// 授權管理 取醫師可授權的醫事人員名單
export function fetchAuthList(partnerid) {
    return axios.get('/api/fetchAuthList/' + partnerid, config)
}
// 授權管理 新增修改授權護士名單(醫師用)
export function updateAuthNurse(payload) {
    return axios.post('/api/updateAuthNurse', payload, config)
}
// 授權管理 刪除授權人員(醫師用)
export function delAuthNurse(payload) {
    return axios.post('/api/delAuthNurse', payload, config)
}
// 授權管理 新增修改授權狀態(醫師用)
export function updateAuth(payload) {
    return axios.post('/api/updateAuth', payload, config)
}
// 更新諮詢費率 (新增紀錄)
export function createDoctorFee(payload) {
    return axios.post('/api/createDoctorFee', payload, config)
}
// 取醫師台灣一般時段的價錢
export function getDoctorGeneralFee(sdid) {
    return axios.get('/api/getDoctorGeneralFee/' + sdid, config)
}
// 掛號主頁 取掛號主頁資料
export function getQueueDetailByQid(qid) {
    return axios.get('/api/getQueueDetailByQid/' + qid, config)
}
// 掛號主頁 取看診紀錄
export function getQueueHistory(payload) {
    return axios.get('/api/getQueueHistory/' + payload.pid + '/' + payload.partnerid + '/' + payload.page, config)
}
// 掛號主頁 搜尋ICD10
export function get_icd10(word) {
    return axios.get('/api/get_icd10/' + word, config)
}
// 掛號主頁 搜尋藥品資訊
export function get_medicine_info(word) {
    return axios.get('/api/get_medicine_info/' + word, config)
}
// 掛號主頁 更新病患主觀自覺徵候
export function updateSubjective(payload) {
    return axios.post('/api/updateSubjective', payload, config)
}
// 掛號主頁 更新醫療人員客觀診斷評估
export function updateObject(payload) {
    return axios.post('/api/updateObject', payload, config)
}
// 掛號主頁 取病人備註
export function fetchPatientNotes(payload) {
    return axios.get('/api/fetchPatientNotes/' + payload.partner_id + '/' + payload.pid, config)
}
// 掛號主頁 新增＆修改病人備註
export function upsertPatientNotes(payload) {
    return axios.post('/api/upsertPatientNotes', payload, config)
}
// 掛號主頁 取病歷ICD (目前沒用)
export function fetchICD(mr_id) {
    return axios.get('/api/fetchICD/' + mr_id, config)
}
// 掛號主頁 新增＆修改病歷ICD
export function upsertICD(payload) {
    return axios.post('/api/upsertICD', payload, config)
}
// 掛號主頁 刪除病歷ICD
export function deleteICD(payload) {
    return axios.post('/api/deleteICD', payload, config)
}
// 掛號主頁 取病歷藥品 (目前沒用)
export function fetchMedicine(mr_id) {
    return axios.get('/api/fetchMedicine/' + mr_id, config)
}
// 掛號主頁 新增＆修改病歷藥品
export function upsertMedicine(payload) {
    return axios.post('/api/upsertMedicine', payload, config)
}
// 掛號主頁 刪除病歷藥品
export function deleteMedicine(payload) {
    return axios.post('/api/deleteMedicine', payload, config)
}
// 掛號主頁 取處方集
export function fetchMedicinePackage(partner_id) {
    return axios.get('/api/fetchMedicinePackage/' + partner_id, config)
}
// 掛號主頁 儲存處方集
export function saveMedicinePackage(payload) {
    return axios.post('/api/saveMedicinePackage', payload, config)
}
// 掛號主頁 取藥品的用法＆途徑
export function fetchMedFreqRoute() {
    return axios.get('/api/fetchMedFreqRoute', config)
}
// 掛號主頁 更新連續處方籤
export function updateContinuousPrescription(payload) {
    return axios.post('/api/updateContinuousPrescription', payload, config)
}
// 掛號主頁 取合作藥局
export function fetchPharmacyList(partner_id) {
    return axios.get('/api/fetchPharmacyList/' + partner_id, config)
}
// device_token資料的防呆機制，並回傳device_token資料
export function checkDeviceToken(payload) {
    return axios.post('/api/checkDeviceToken', payload, config)
}
// 儲存處方籤QRCode字串內容
export function getRxString(payload) {
    return axios.post('/api/getRxString', payload, config)
}
// 發送email寄出處方籤
export function sendPrescription(payload) {
    return axios.post('/api/sendPrescription', payload, config)
}
// 取處方籤資料
export function getRXData(payload) {
    return axios.post('/api/getRXData', payload, config)
}
// 檢查民眾是否有deviceToken
export function get_patient_device(mid) {
    return axios.get('/api/get_patient_device/' + mid, config)
}
// 取HIS config
export function getConfig(partner_id) {
    return axios.get('/api/getConfig/' + partner_id, config)
}
// 主選單 取個人權限
export function getpermissionlist() {
    return axios.get('/api/getpermissionlist', config)
}
// 民眾，房間狀態
// export function PatientStateFromMcu(data) {
//     return axios.post(process.env.VUE_APP_API_URL + '/admin/state?queue_id=' + data.qid + '&doctor=0&mid=' + data.mid)
// }
export function sendMidToApp(data) {
    return axios.get('/api/sendMidToApp', data, config)
}
// 發送視訊邀請(含通知)
export function inviteVideo(payload) {
    return axios.post('/api/inviteVideo', payload, config)
}
// 病歷 取華育生殖檢驗報告
export function getFertilityReport(payload) {
    return axios.post('https://external-dev.curdoctor.com.tw/huayu/getHuayuReport', payload, config)
}
// 醫療影像同步 (備用API)
export function medicalFileSync(data) {
    return axios.post('/api/medicalFileSync', data, config)
}
// 取醫事人員職稱
export function fetchMedicalTitle() {
    return axios.get('/api/fetchMedicalTitle/', config)
}
// 修改醫事人員職稱
export function updateMedicalTitleId(data) {
    return axios.post('/api/updateMedicalTitleId/', data, config)
}
// 掛號主頁 取完診後的醫囑修改紀錄
export function fetchMRHistory(mr_id) {
    return axios.get('/api/fetchMRHistory/' + mr_id, config)
}
// 取這個合作夥伴裡可選擇的醫師名單
export function fetchDoctorListByPartner(partner_id) {
    return axios.get('/api/fetchDoctorListByPartner/' + partner_id, config)
}
// 取這個合作夥伴裡可選擇的醫師名單
export function searchQueueLog(payload) {
    return axios.post('/api/searchQueueLog', payload, config)
}
// 取p2p影片清單
export function getVideoList(payload) {
    return axios.post(process.env.VUE_APP_COMM_API + '/room/records', payload)
}
// 取p2p影片清單
export function createRoom() {
    return axios.post(process.env.VUE_APP_COMM_API + '/room/create')
}
// 取合作夥伴的到院看診狀態
export function fetchPartnerSite(partner_id) {
    return axios.get('/api/fetchPartnerSite/' + partner_id, config)
}
// 義診 取量測數據（依qid）
export function fetchMonitorData(payload) {
    return axios.post('/api/fetchMonitorData', payload, config)
}
// 義診 取健檢進度（依qid Array）
export function fetchMonitorProgress(payload) {
    return axios.post('/api/fetchMonitorProgress', payload, config)
}
// 查詢合作夥伴裡可選擇的醫師名單(需要使用在轉接或通話 即時更新和判斷上線)
export function QueryDoctorListByPartner(payload) {
    return axios.post('/api/QueryDoctorListByPartner', payload, config)
}
// 查詢合作夥伴裡可選擇的醫師名單(單純掛號使用 不需要搜尋和判斷狀態 不會隨時呼叫更新)
export function ContactsForReservation(payload) {
    return axios.post('/api/ContactsForReservation', payload, config)
}
// 班表 取醫師班表
export function fetchShift(payload) {
    return axios.post('/api/fetchShift', payload, config)
}
// 班表 更新固定班表
export function updateShift(payload) {
    return axios.post('/api/updateShift', payload, config)
}
// 班表 更新請假
export function updateLeave(payload) {
    return axios.post('/api/updateLeave', payload, config)
}
// 飲食管理 取會員列表資料
export function fetchDietMember(payload) {
    return axios.post('/api/fetchDietMember', payload, config)
}
// 飲食管理 取飲食log詳細資料
export function fetchDietInfo(payload) {
    return axios.post('/api/fetchDietInfo', payload, config)
}
// 飲食管理 取會員詳細資料
export function fetchDietMemberDetail(payload) {
    return axios.post('/api/fetchDietMemberDetail', payload, config)
}
// 飲食管理 取會員圖表資料(一年份)
export function fetchDietChartData(payload) {
    return axios.post('/api/fetchDietChartData', payload, config)
}
// 飲食管理 取食物建議值
export function getDietStandard(payload) {
    return axios.post('/api/getDietStandard', payload, config)
}
// 飲食管理 新增修改食物建議值
export function insertDietStandard(payload) {
    return axios.post('/api/insertDietStandard', payload, config)
}
// 義診 更新心電圖資料
export function updateDicomValue(payload) {
    return axios.post('/api/updateDicomValue', payload, config)
}
// 掛號管理 取掛號
export function fetchAppt(payload) {
    return axios.post('/api/fetchAppt', payload, config)
}
// 掛號管理 取今日掛號未完診數量
export function fetchApptUndoneQuantity(payload) {
    return axios.post('/api/fetchApptUndoneQuantity', payload, config)
}
// 取K2健檢報告
export function getK2Report(spid) {
    return axios.get('/api/getK2Report/' + spid, config)
}
// 取服務記錄
export function fetchServiceRecord(payload) {
    return axios.post('/api/fetchServiceRecord', payload, config)
}
// 取服務記錄數量
export function fetchSRQuantity(payload) {
    return axios.post('/api/fetchSRQuantity', payload, config)
}
// 新增服務記錄的案件註記
export function insertServiceRecordDetail(payload) {
    return axios.post('/api/insertServiceRecordDetail', payload, config)
}
// 新增服務案件
export function insertServiceNote(payload) {
    return axios.post('/api/insertServiceNote', payload, config)
}
// 取服務案件標籤
export function fetchCaseTags(payload) {
    return axios.post('/api/fetchCaseTags', payload, config)
}
// 交辦案件
export function updatecaseowner(data) {
    return axios.post('/api/updatecaseowner', data, config)
}
// 全部結案
export function closeAllCase(data) {
    return axios.post('/api/closeAllCase', data, config)
}
// 飲食管理 取一餐資料
export function fetchdietnutrients(payload) {
    return axios.post('/api/fetchdietnutrients', payload, config)
}
// 飲食管理 醫師寫分析
export function insertDietDoctor(payload) {
    return axios.post('/api/insertDietDoctor', payload, config)
}
// 上傳醫療影像
export function uploadPhoto(payload) {
    return axios.post('/api/uploadphoto/2', payload, config)
}
// 遠距監看 新增或修改 正常數值範圍
export function upsertDataRange(payload) {
    return axios.post('/api/upsertDataRange', payload, config)
}
// 遠距監看 取會員資料
export function getMonitorData(payload) {
    return axios.post('/api/getMonitorData_V2', payload, config)
}
// 商城 取得產品列表
export function searchcartproduct(payload) {
    return axios.post('/api/searchCartProduct', payload, config)
}
// 商城 新增MR推薦商品
export function insertRecommend(payload) {
    return axios.post('/api/insertRecommend', payload, config)
}
// 商城 取商城config
export function getAllowShop(payload) {
    return axios.post('/api/getAllowShop', payload, config)
}
// 商城 取ＭＲ商城資料
export function getRecommend(payload) {
    return axios.post('/api/getRecommend', payload, config)
}
// GHealth 取員工健檢報告
export function getGHealthReport(payload) {
    return axios.get('/external/getGHealthReport/' + payload.spid + '/' + payload.partner_id, config)
}
// 會員管理 取單位和群組
export function getMemberFilter(payload) {
    return axios.post('/api/getMemberFilter', payload, config)
}
// 會員管理 取會員
export function getMemberList(payload) {
    return axios.post('/api/getMemberList', payload, config)
}
//Add member
export function addMember(payload) {
    return axios.post('/api/addMember', payload, config)
}

// 取商城Token
export function getTokenFromCart(payload) {
    return axios.get('/api/getTokenFromCart', payload, config)
}
// 新增常用商品
export function setProduct(payload) {
    return axios.post('/api/setProduct', payload, config)
}
// 刪除常用商品
export function deleteProductFromDB(payload) {
    return axios.post('/api/deleteProduct', payload, config)
}
// 刪除常用商品 ???
export function getDoctorProducts(payload) {
    return axios.post('/api/getDoctorProducts', payload, config)
}
// 通知並邀請民眾或民眾家屬加入視訊
export function sendNotifyAndVideoLink(payload) {
    return axios.post('/api/sendNotifyAndVideoLink', payload, config)
}
// 打給民眾家屬（暫時用來代替p2p）（dirty）
// export function callFamily(payload) {
//   return axios.post('/api/callFamily' , payload, config)
// }
// P2P通話
export function call_p2p(payload) {
    return axios.post('/api/call', payload, config)
}
// 純通話不綁掛號
export function simple_call(payload) {
    return axios.post('/api/simple_call', payload, config)
}
// 取通話紀錄
export function fetchCallLog(payload) {
    return axios.post('/api/fetchCallLog', payload, config)
}
// 取高登身心指數HRV
export function getHRVByMid(payload) {
    return axios.post('/api/getGoldenHRV', payload, config)
}
// 取使用者角色
export function getStaffType(payload) {
    return axios.post('/api/getStaffType', payload, config)
}

// 取通話模式
export function getCallType(partner_id) {
    return axios.get('/api/getCallType/' + partner_id, config)
}
//病歷 新增數據寫health data
export function updateHealthdataFromEmr(data) {
    return axios.post('/api/updateHealthdataFromEmr', data, config)
}
export function updateCaseTags(data) {
    return axios.post('/api/updateCaseTags', data, config)
}

// 取常用字句（跟原本getWords差在可以取別的醫事人員的設定，代理掛號會用到）
export function getCanWords(payload) {
    return axios.post('/api/getCanWords', payload, config)
}

// 電子簽名 紀錄到 partner_pdf_signed 表中
export function recordSignature(payload) {
    return axios.post('/api/recordSignature', payload, config)
}

// 杏翔 自動登入
export function theseAutoLogin(payload) {
    return axios.post('/api/theseAutoLogin', payload, config)
}

// ******************************** 之後寫的api ********************************

// 取掛號
export function getQueues(payload) {
    return axios.post('/api/getQueues', payload, config)
}

// 取未完成掛號數量
export function getQueuesUndoneQuantity(payload) {
    return axios.post('/api/getQueuesUndoneQuantity', payload, config)
}

// 通話
export function call(payload) {
    return axios.post('/api/call', payload, config)
}

// 邀請
export function invite(payload) {
    return axios.post('/api/invite', payload, config)
}

// 轉接醫師，沒有通話的部分，只有純粹補個mr
export function transfer(payload) {
    return axios.post('/api/transfer', payload, config)
}

// 取通話清單
export function getCallList(payload) {
    return axios.post('/api/getCallList', payload, config)
}

// 取房間清單
export function getRoomList(payload) {
    return axios.post('/api/getRoomList', payload, config)
}

// ****************************************** 義診 ******************************************

// 取活動
export function getEvents(payload) {
    return axios.post('/api/getEvents', payload, config)
}

// 義診報名
export function joinEvent(payload) {
    return axios.post('/api/joinEvent', payload, config)
}

// 義診掛號
export function makeEventAppt(payload) {
    return axios.post('/api/makeEventAppt', payload, config)
}

// 取義診活動所有會員的量測數據
export function getEventMeasureData(payload) {
    return axios.post('/api/getEventMeasureData', payload, config)
}

// 取義檢活動量測項目
export function getEventItemList(payload) {
    return axios.post('/api/getEventItemList', payload, config)
}

// 寫入量測數據
export function upsertMeasureData(payload) {
    return axios.post('/api/upsertMeasureData', payload, config)
}

// 掃描健保卡或身分證
export function scanCard(payload) {
    return axios.post('/api/scanCard', payload, config)
}

// 取TB判讀
export function getTbFiles(payload) {
    return axios.post('/api/getTbFiles', payload, config)
}

// TB診斷
export function diagnosisTb(payload) {
    return axios.post('/api/diagnosisTb', payload, config)
}

// 取 HIS 設定檔
export function getHisConfig(payload) {
    return axios.post('/api/getHisConfig', payload, config)
}

// 取雲端位置檔案
export function getDriveFileList(payload) {
    return axios.post('/api/getDriveFileList', payload, config)
}

// 取雲端位置檔案
export function updateDriveFileName(payload) {
    return axios.post('/api/updateDriveFileName', payload, config)
}

// 取整篩活動
export function getHpaEvents(payload) {
    return axios.post('/api/getHpaEvents', payload, config)
}

// 取整篩活動報名民眾
export function getHpaEventPatients(payload) {
    return axios.post('/api/getHpaEventPatients', payload, config)
}

// 整篩活動報名
export function joinHpaEvent(payload) {
    return axios.post('/api/joinHpaEvent', payload, config)
}

// 取消整篩活動報名
export function cancelHpaEventRegistration(payload) {
    return axios.post('/api/cancelHpaEventRegistration', payload, config)
}
