<template>
    <vs-popup :title="$t('components.sendMsg.title', { name: sname })" :active.sync="popupActive" style="z-index: 51001;" @close="close">
        <div class="p-0">
            <VuePerfectScrollbar class="border border-solid d-theme-border-grey-light" :settings="settings" id="scrollbar">
                <div style="height: 60vh;">
                    <div v-if="showChatData">
                        <div class="p-2" v-for="(msg, index) in showChatData" :key="index">
                            <!-- If previous msg is older than current time -->
                            <template v-if="showChatData[index-1]">
                                <vs-divider v-if="!isSameDay(msg.time, showChatData[index-1].time)" class="msg-time">
                                    <span>{{ toDate(msg.time) }}</span>
                                </vs-divider>
                                <div class="spacer" v-if="!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent)"></div>
                            </template>
                            <div class="flex items-start" :class="[{'flex-row-reverse' : msg.isSent}]">
                                <!-- 已收回訊息 -->
                                <div class="mx-1 mb-3 p-2 text-center text-sm bg-grey text-white" style="border-radius: 10px;" v-if="msg.del == 1">
                                    {{ $t('components.sendMsg.retractMsg', { name: msg.isSent ? dname : sname }) }}
                                </div>
                                <!-- 大頭貼 -->
                                <template v-if="showChatData[index-1] && !msg.isSent">
                                    <template v-if="(!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent) || !isSameDay(msg.time, showChatData[index-1].time))">
                                        <vs-avatar :size="windowWidth >= 415 ? '40px' :'35px'" class=" border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="msg.isSent ? 'sm:ml-2 ml-1' : 'sm:mr-2 mr-1'"></vs-avatar>
                                    </template>
                                </template>
                                <template v-if="index == 0 && !msg.isSent">
                                    <vs-avatar size="40px" class="border-2 shadow border-solid border-white m-0 flex-shrink-0" :class="msg.isSent ? 'sm:ml-2 ml-1' : 'sm:mr-2 mr-1'"></vs-avatar>
                                </template>
                                <!-- 頭貼下的空白處 -->
                                <template v-if="showChatData[index-1] && !msg.isSent">
                                    <div class="mr-10 sm:mr-12" v-if="!(!hasSentPreviousMsg(showChatData[index-1].isSent, msg.isSent) || !isSameDay(msg.time, showChatData[index-1].time))"></div>
                                </template>
                                <div class="mx-1" style="text-align:start;">
                                    <!-- 群組聊天室裡的名字 -->
                                    <template v-if="msg.name">
                                        <div class="msg break-words" style="font-size:10px;" :class="{'text-white': msg.isSent, 'border-grey-light': !msg.isSent}" v-show="!msg.isSent">{{ msg.name }}</div>
                                    </template>
                                    <!-- 所有訊息 -->
                                    <div class="shadow-md chat max-w-xs" :class="{'border border-solid border-grey-light bg-white': !msg.isSent}" v-if="msg.del == 0">
                                        <div style="overflow-wrap: break-word; white-space: normal;" v-html="msg.textContent"></div>
                                    </div>
                                </div>
                                <div class="mx-1 mb-3" style="line-height:1em; text-align:center; align-self: flex-end;" v-if="msg.del == 0">
                                    <!-- 已讀 -->
                                    <span class="stamp" v-show="msg.isSent==true && msg.isSeen==true">{{ $t('components.sendMsg.msgIsSeen') }}<br></span>
                                    <!-- 時間 -->
                                    <span class="stamp">{{msg.time | moment("HH:mm")}}</span>
                                </div>
                                <!-- 收回按鈕 -->
                                <vs-button class="p-2 mb-1" style="align-self: flex-end;" type="flat" color="dark" radius @click="confirmRecycleMessage(msg.msgid)" v-show="msg.isSent==true" v-if="msg.del == 0">
                                    <i class="el-icon-delete"></i>
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </VuePerfectScrollbar>
            <div class="flex py-4 items-center">
                <import-set-word :dataType="'wd/all'" :uiType="'icon'" @importMsg="importMsg($event)"></import-set-word>
                <vs-input
                class="w-full text-base"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
                resize="none"
                placeholder=""
                v-model="msg"
                @keydown.enter.exact.prevent.native
                @keydown.shift.enter.exact.prevent.native
                @keyup.enter.exact.native="sendMsg"
                @keyup.shift.enter.exact.native="lineFeed()"/>
                <span class="px-2 text-primary cursor-pointer text-lg" @click="sendMsg">➤</span>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import importSetWord from '@/components/importSetWord'
import { saveChatData, fetchChatData, fetchExcelChatLog, getCountByUnreadMsg, recycleChatData } from '@/api/user'

export default {
    components: {
        importSetWord,
        VuePerfectScrollbar
    },
    props: {
        chatPopup: {
            type: Boolean,
            default: false,
            required: true
        },
        smid: {
            type: Number,
            required: false
        },
        sname: {
            type: String,
            required: false
        }
    },
    watch: {
        showChatData(n, o) {
            if(o != null){
                if(this.page == 1){
                    this.$nextTick(() => {
                        this.scrollbarElement.scrollTop = this.scrollbarElement.scrollHeight
                    })
                }else{
                    this.$nextTick(() => {
                        this.scrollbarElement.scrollTop = this.scrollbarElement.scrollHeight - this.tempScrollHeight
                    })
                }
                return
            }
            this.scrollbarElement.addEventListener('scroll', this.scrollbarListener)
            this.$nextTick(() => {
                this.scrollbarElement.scrollTop = this.scrollbarElement.scrollHeight - this.scrollbarElement.clientHeight
            })
        },
        page(n){
            if(n>=2){
                this.tempScrollHeight = this.scrollbarElement.scrollHeight
            }
        },
        socketReceiveMsg(){
            if(!this.chatPopup) return
            this.showChatData.push({
                isSeen:      false,
                isSent:      false,
                textContent: this.socketReceiveMsg.msg_txt,
                time:        this.socketReceiveMsg.date_time,
                del:         0,
            })
            this.getCountByUnreadMsg()
            this.$nextTick(() => {
                this.scrollbarElement.scrollTop = this.scrollbarElement.scrollHeight
            })
        }
    },
    computed: {
        ...mapState(['partner_id']),
        popupActive: {
            get() {
                if(this.chatPopup){
                    this.fetchChatData()
                    this.getCountByUnreadMsg()
                    this.scrollbarElement = document.getElementById('scrollbar')
                }
                return this.chatPopup
            },
            set(v) {
                return v
            },
        },
        hasSentPreviousMsg() {
            return (last_sender, current_sender) => last_sender == current_sender
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        socketReceiveMsg() {
            var data = this.$store.state.socketReceiveMsg
            if(!data || data.entityType !== 'msg') return
            return this.$store.state.socketReceiveMsg.data[0]
        },
        dateTime() {
            return this.$store.getters.dateTime
        },
        dname() {
            return this.$store.getters.name
        },
    },
    data() {
        return {
            msg: '',
            showChatData: null,
            settings: {
                maxScrollbarLength : 20,
                wheelSpeed         : 0.70,
            },
            scrollbarElement: null,
            kind: 0, // 0:民眾 1:醫事人員 2:群組
            page: 1,
            nodata: false,
            tempScrollHeight: 0,
            delmsg: '',
        }
    },
    methods: {
        // 取訊息
        fetchChatData() {
            if(!this.smid) return
            const payload = {
                "pmid": this.smid,
                "kind": this.kind,
                "page": this.page
            }
            fetchChatData(payload).then(res => {
                if(res.data.status === 'OK'){
                    if(res.data.chats.msg.length == 0){
                        this.nodata = true
                        return
                    }
                    if(this.page == 1){
                        this.showChatData = _.cloneDeep(res.data.chats.msg)
                    }else{
                        this.showChatData = res.data.chats.msg.concat(this.showChatData)
                    }
                }
            })
        },
        // 傳送訊息
        sendMsg() {
            if(_.trim(this.msg) == '') return
            this.msg.replace(/\n/g,"<br />");
            var payload = {
                'id': this.smid,
                'to_mid_type': 0,
                'msg_group_id': 0,
                'msg': {
                    "textContent": this.msg,
                    "time": this.dateTime,
                },
                'type': "member",
                'partner_id': this.partner_id
            }
            saveChatData(payload).then(res => {
                if(res.data.status == 'OK'){
                    this.page = 1
                    this.fetchChatData()
                    this.msg = ''
                }
            })
        },
        // 匯入常用字句
        importMsg(msg){
            // 判斷最後一個字元是不是換行符號
            let lastWord = this.msg.split("")[this.msg.split("").length-1]
            if(lastWord != '\n' && lastWord != undefined) {
                this.msg = this.msg + '\n'
            }
            this.msg += msg
        },
        // 視窗通知
        notify(color, title, text){
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position:'top-center'
            })
        },
        close(){
            this.msg = ''
            this.showChatData = null
            this.page = 1
            this.scrollbarElement.removeEventListener('scroll', this.scrollbarListener);
            this.nodata = false
            this.$emit("closeChat", false)
        },
        // 判斷訊息是否為今天
        isSameDay(time_to, time_from) {
            return  this.$moment(time_to).format("YYYY") === this.$moment(time_from).format("YYYY") &&
                    this.$moment(time_to).format("MM") === this.$moment(time_from).format("MM") &&
                    this.$moment(time_to).format("DD") === this.$moment(time_from).format("DD");
        },
        // 顯示日期文字
        toDate(time) {
            var m = this.$moment(time).locale('en');
            const monthName = m.localeData().monthsShort(m);
            const DD = this.$moment(time).format("DD");
            return DD + ' '  + monthName;
        },
        // 滑動觸發 debounce->避免重複執行
        scrollbarListener: debounce(function(event){
            var scrollTop = this.scrollbarElement.scrollTop
            var scrollHeight = this.scrollbarElement.scrollHeight
            var bottomOfscrollbarElement = scrollHeight - scrollTop
            if (bottomOfscrollbarElement == scrollHeight && this.nodata == false) {
                this.page++
            }
        }, 200),
        // 換行
        lineFeed () {
			this.msg = this.msg + '\n'
		},
        // 取未讀訊息數量
        getCountByUnreadMsg() {
            getCountByUnreadMsg().then(res => {
                if(res.data.status=='OK'){
                    this.$store.commit('SET_MSG_UNFINISH_TOTAL',res.data.count)
                }
            }).catch((error) => {
                console.log('msg count : '+error)
            })
        },
        // 收回訊息的確認視窗
        confirmRecycleMessage(msgid){
            this.delmsg = msgid
            this.$vs.dialog({
                type:   'confirm',
                color:  'danger',
                title:  this.$t('popup.alert'),
                text:   this.$t('components.sendMsg.retractTip'),
                accept: this.recycleMessage
            })
        },
        // 收回訊息
        recycleMessage(){
            this.$vs.loading()
            recycleChatData({'msgid': this.delmsg}).then(res => {
                if(res.data.status=='OK'){
                    let index = _.findIndex(this.showChatData, ['msgid', this.delmsg])
                    this.showChatData[index].del = 1
                }
                this.$vs.loading.close()
            })
        },
    },
}
</script>