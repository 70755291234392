<template>
    <div>
        <!-- 房間清單 -->
        <!-- <RoomList :openPopup="activeRoom" @closePopup="closeRoom"></RoomList> -->
        <!-- 通話清單 -->
        <CallList
            :openPopup="activeCall"
            :title="callListPayload.title.toString()"
            :pid="callListPayload.pid.toString()"
            :noContact="callListPayload.noContact"
            :noEnterprise="callListPayload.noEnterprise"
            :noPartner="callListPayload.noPartner"
            :qid="callListPayload.qid.toString()"
            :sn_id="callListPayload.sn_id.toString()"
            :transfer="callListPayload.transfer"
            @closePopup="closeCall"
        ></CallList>
        <!-- 選擇合作夥伴視窗 -->
        <selectPartner :openPopup="activeSelectPartner" @closePartnerPopup="closePartner"></selectPartner>
        <!-- 掛號視窗 -->
        <appt :activePopup="activeAppt" :patientData="patient" :mode="apptMode" @closeApptPopup="closeAppt"></appt>
        <!-- 聊天室視窗 -->
        <chat :chatPopup="activeChat" :smid="parseInt(smid)" :sname="sname" @closeChat="closeChat"></chat>
        <!-- 發送簡訊邀請民眾視窗 -->
        <invite-popup :invitePopupActive="activeInvite" :qid="invite.qid ? parseInt(invite.qid) : 0" :room_id="invite.room_id ? parseInt(invite.room_id) : 0" :pmid="invite.mid" @closePopup="closeInvite"></invite-popup>
        <!-- Error dialog -->
        <errDialog :openPopup="activeErrDialog"
            :title="errDialogPayload.title"
            :content="errDialogPayload.content"
            @closeErrDialogPopup="closeErrDialog"
        ></errDialog>
    </div>
</template>
<script>
import errDialog from '@/components/errDialog.vue'
import selectPartner from '@/components/selectPartner.vue'
import appt from '@/components/apptBtn.vue'
import chat from '@/components/sendChat'
import InvitePopup from '@/components/invitePopup'
import CallList from '@/components/Call/CallList'
// import RoomList from '@/components/Room/RoomList'
export default {
    components: { errDialog, selectPartner, appt, chat, InvitePopup, CallList },
    data() {
        return {
            // activeRoom: false,
            activeCall: false,
            activeSelectPartner: false,
            activeErrDialog: false,
            activeAppt: false,
            activeChat: false,
            activeInvite: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            smid: 0,
            sname: '',
            invite: {
                qid: 0,
                room_id: 0,
                mid: 0,
            },
            callListPayload: {
                title: this.$t('components.call.call'),
                pid: 0,
                noContact: false,
                noEnterprise: false,
                noPartner: false,
                qid: 0,
                sn_id: 0,
                transfer: false,
            },
            errDialogPayload: {
                title: '',
                content: ''
            }
        }
    },
    created() {
        this.createBus()
    },
    methods: {
        createBus() {
            this.$bus.$on('selectPartner', () => {
                this.openPartner()
            })
            this.$bus.$on('appt', (item) => {
                this.apptMode = item.apptMode
                this.patient = item.patient
                this.openAppt()
            })
            this.$bus.$on('chat', (item) => {
                this.smid = item.smid
                this.sname = item.sname
                this.openChat()
            })
            this.$bus.$on('invite', (item) => {
                this.invite = item
                this.openInvite()
            })
            this.$bus.$on('callList', (item) => {
                if (item.title !== undefined) this.callListPayload.title = item.title
                if (item.pid !== undefined) this.callListPayload.pid = item.pid
                if (item.noContact !== undefined) this.callListPayload.noContact = item.noContact
                if (item.noEnterprise !== undefined) this.callListPayload.noEnterprise = item.noEnterprise
                if (item.noPartner !== undefined) this.callListPayload.noPartner = item.noPartner
                if (item.qid !== undefined) this.callListPayload.qid = item.qid
                if (item.sn_id !== undefined) this.callListPayload.sn_id = item.sn_id
                if (item.transfer !== undefined) this.callListPayload.transfer = item.transfer
                this.openCall()
            })

            this.$bus.$on('errDialog', (item) => {
                console.log('errDialog = ', item)
                this.errDialogPayload.content = item.content
                this.errDialogPayload.title = item.title
                console.log("errDialogPayload = ", this.errDialogPayload)
                this.openErrDialog()
            })
        },
        // openRoom() {
        //     this.activeRoom = true
        // },
        // closeRoom() {
        //     this.activeRoom = false
        // },
        openCall() {
            this.activeCall = true
        },
        closeCall() {
            this.callListPayload = {
                title: this.$t('components.call.call'),
                pid: 0,
                noContact: false,
                noEnterprise: false,
                noPartner: false,
                qid: 0,
                sn_id: 0,
                transfer: false,
            }
            this.activeCall = false
        },
        openErrDialog() {
            this.activeErrDialog = true
        },
        closeErrDialog() {
            this.activeErrDialog = false
        },
        openPartner() {
            this.activeSelectPartner = true
        },
        closePartner() {
            this.activeSelectPartner = false
        },
        openAppt() {
            this.activeAppt = true
        },
        closeAppt() {
            this.activeAppt = false
        },
        openChat() {
            this.activeChat = true
        },
        closeChat() {
            this.activeChat = false
        },
        openInvite() {
            this.activeInvite = true
        },
        closeInvite() {
            this.activeInvite = false
        },
    },
    beforeDestroy() {
        this.$bus.$off('selectPartner')
        this.$bus.$off('appt')
        this.$bus.$off('chat')
        this.$bus.$off('invite')
        this.$bus.$off('callList')
        this.$bus.$off('errDialog')

    },
}
</script>
